import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AllCoursesView.css';

const AllCoursesViewAdmin = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const handleCreateCourse = () => {
        navigate('/admin/create-course');
    };
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${global.baseUrl}/api/v1/course`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCourses(response.data.Courses);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setLoading(false);
                toast.error('Failed to fetch courses. Please try again.');
            }
        };

        fetchCourses();
    }, []);

    const handleRegister = async (courseId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://tshub.torh.tech/api/v1/course/student',
                { course: courseId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.status === 201) {
                toast.success('Course Registration Successful');
            } else {
                toast.error('Unexpected error occurred. Please try again.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data);
                }
            } else {
                console.error('Error registering course:', error);
                toast.error('Failed to connect to the server. Please try again later.');
            }
        }
    };
    const handleCourseClick = (courseId) => {
        navigate(`/admin/course/${courseId}`);
    };
    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className='container'>
            <header>
                <h1 className="title">All Courses</h1>
            </header>
            <button id="button" onClick={() => handleCreateCourse()} className='register-link'>Create Course</button>

            <main>
                <div className='all-course-list'>
                    {courses.map(course => (
                        <div className="course" key={course.id}>
                            <img src={course.image} alt={course.title} className="courseImage" onClick={() => handleCourseClick(course.id)} />

                            <div className="course-details">
                                <h2 className="course-title">{course.title}</h2>
                                <p className="course-description">Description: {course.description}</p>
                                <p className="course-fee">Fee: {course.fee_currency} {course.fee}</p>
                            </div>
                            <button id="button" onClick={() => deleteCourse(course.id)} className='delete-link'>Delete</button>
                        </div>
                    ))}
                </div>
            </main>
        </div>
    );
};

export default AllCoursesViewAdmin;
