import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/CreateProject.css';
import { useParams } from 'react-router-dom';

const CreateProject = () => {
    const { courseId } = useParams();

    // const [projectData, setProjectData] = useState({
    //     title: '',
    //     course: courseId,
    //     averageScore: '',
    //     postedBy: '',
    //     description: '',
    //     concepts: [''],
    //     resources: [''],
    //     objectives: [''],
    //     moreInfo: ''
    // });
    const [projectData, setProjectData] = useState({
        title: '',
        course: courseId,
        averageScore: '',
        postedBy: '',
        description: '',
        concepts: [''],
        resources: [{ title: '', sub_title: '', description: '', url: '' }], // Initialize as an array of one object
        objectives: [''],
        moreInfo: ''
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData({ ...projectData, [name]: value });
    };



    const handleAddResource = () => {
        setProjectData(prevState => ({
            ...prevState,
            resources: [
                ...prevState.resources,
                {
                    title: '',
                    sub_title: '',
                    description: '',
                    url: ''
                }
            ]
        }));
    };
    const handleRemoveResource = (index) => {
        setProjectData(prevState => ({
            ...prevState,
            resources: prevState.resources.filter((_, i) => i !== index)
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const dataToSend = {
                ...projectData,
                course: courseId
            };
            console.log({ courseId })
            const response = await axios.post(`${global.baseUrl}/api/v1/project/upload-project`, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 201) {
                toast.success('Project created successfully');
            } else {
                toast.error(response.data.message);

            }
        } catch (error) {
            console.error('Error creating project:', error);
            toast.error(error.response.data);

        }
    };
    const handleAddConcept = () => {
        setProjectData(prevState => ({
            ...prevState,
            concepts: [...prevState.concepts, '']
        }));
    };

    const handleRemoveConcept = (index) => {
        setProjectData(prevState => ({
            ...prevState,
            concepts: prevState.concepts.filter((_, i) => i !== index)
        }));
    };

    const handleAddObjective = () => {
        setProjectData(prevState => ({
            ...prevState,
            objectives: [...prevState.objectives, '']
        }));
    };

    const handleRemoveObjective = (index) => {
        setProjectData(prevState => ({
            ...prevState,
            objectives: prevState.objectives.filter((_, i) => i !== index)
        }));
    };
    return (
        <div className="CreateProjectMainCont">
            <h2 className="CreateProjectTitle">Create Project</h2>
            <form onSubmit={handleSubmit} className="CreateProjectForm">
                <label htmlFor="title" className="InputTitle">Title:</label>
                <input type="text" id="title" name="title" value={projectData.title} onChange={handleChange} className="CreateProjectInput" required />

                <label htmlFor="averageScore" className="InputTitle">Average Score:</label>
                <input type="number" id="averageScore" name="averageScore" value={projectData.averageScore} onChange={handleChange} className="CreateProjectInput" />

                <label htmlFor="postedBy" className="InputTitle">Posted By:</label>
                <input type="text" id="postedBy" name="postedBy" value={projectData.postedBy} onChange={handleChange} className="CreateProjectInput" />

                <label htmlFor="description" className="InputTitle">Description:</label>
                <textarea id="description" name="description" value={projectData.description} onChange={handleChange} className="CreateProjectTextarea"></textarea>

                <div className="InputWithButton">
                    {/* Concepts */}
                    <label htmlFor="concepts" className="InputTitle">Concepts:</label>
                    {projectData.concepts.map((concept, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={concept}
                                onChange={(e) => {
                                    const concepts = [...projectData.concepts];
                                    concepts[index] = e.target.value;
                                    setProjectData({ ...projectData, concepts });
                                }}
                                className="CreateProjectInput"
                            />
                            {/* Render remove button only if there are more than one concept */}
                            {projectData.concepts.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => handleRemoveConcept(index)}
                                    className="RemoveButton"
                                >
                                    Remove
                                </button>
                            )}
                            {/* Add <hr> after each concept except the last one */}
                            {index < projectData.concepts.length - 1 && <hr />}
                        </div>
                    ))}
                    <button id="button" type="button" onClick={handleAddConcept} className="AddButton">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="InputWithButton">
                    <label htmlFor="resources" className="InputTitle">Resources:</label>
                    {projectData.resources.map((resource, index) => (
                        <div key={index}>
                            <label htmlFor={`resource-title-${index}`} className="InputTitle">Resource Title:</label>
                            <input
                                type="text"
                                value={resource.title}
                                onChange={(e) => {
                                    const newResources = [...projectData.resources];
                                    newResources[index].title = e.target.value;
                                    setProjectData({ ...projectData, resources: newResources });
                                }}
                                className="CreateProjectInput"
                            />

                            <label htmlFor={`resource-subtitle-${index}`} className="InputTitle">Resource Subtitle:</label>
                            <input
                                type="text"
                                value={resource.sub_title}
                                onChange={(e) => {
                                    const newResources = [...projectData.resources];
                                    newResources[index].sub_title = e.target.value;
                                    setProjectData({ ...projectData, resources: newResources });
                                }}
                                className="CreateProjectInput"
                            />

                            <label htmlFor={`resource-description-${index}`} className="InputTitle">Resource Description:</label>
                            <textarea
                                value={resource.description}
                                onChange={(e) => {
                                    const newResources = [...projectData.resources];
                                    newResources[index].description = e.target.value;
                                    setProjectData({ ...projectData, resources: newResources });
                                }}
                                className="CreateProjectTextarea"
                            ></textarea>

                            <label htmlFor={`resource-url-${index}`} className="InputTitle">Resource URL:</label>
                            <input
                                type="text"
                                value={resource.url}
                                onChange={(e) => {
                                    const newResources = [...projectData.resources];
                                    newResources[index].url = e.target.value;
                                    setProjectData({ ...projectData, resources: newResources });
                                }}
                                className="CreateProjectInput"
                            />

                            {/* Remove button - Render only if there are more than one resource */}
                            {projectData.resources.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => handleRemoveResource(index)}
                                    className="RemoveButton"
                                >
                                    Remove
                                </button>
                            )}

                            {/* Add <hr> after each resource except the last one */}
                            {index < projectData.resources.length - 1 && <hr />}
                        </div>
                    ))}
                    <button id="button" type="button" onClick={handleAddResource} className="AddButton">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>


                <div className="InputWithButton">
                    {/* Objectives */}
                    <label htmlFor="objectives" className="InputTitle">Objectives:</label>
                    {projectData.objectives.map((objective, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={objective}
                                onChange={(e) => {
                                    const objectives = [...projectData.objectives];
                                    objectives[index] = e.target.value;
                                    setProjectData({ ...projectData, objectives });
                                }}
                                className="CreateProjectInput"
                            />
                            {/* Render remove button only if there are more than one objective */}
                            {projectData.objectives.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => handleRemoveObjective(index)}
                                    className="RemoveButton"
                                >
                                    Remove
                                </button>
                            )}
                            {/* Add <hr> after each objective except the last one */}
                            {index < projectData.objectives.length - 1 && <hr />}
                        </div>
                    ))}
                    <button id="button" type="button" onClick={handleAddObjective} className="AddButton">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>

                <label htmlFor="moreInfo" className="InputTitle">More Info:</label>
                <textarea id="moreInfo" name="moreInfo" value={projectData.moreInfo} onChange={handleChange} className="CreateProjectTextarea"></textarea>

                <button type="submit" className="CreateProjectButton">Create Project</button>
            </form>
            <ToastContainer position="top-center" />
        </div>
    );

};

export default CreateProject;
