import { useLocation } from 'react-router-dom';
import React from 'react';
import at from '../assests/svgFiles/at.svg';
import ig from '../assests/svgFiles/ig.svg';
import x from '../assests/svgFiles/x.svg';
import sin from '../assests/svgFiles/sin.svg';
import fbs from '../assests/svgFiles/fbs.svg';

const BottomDiv = () => {
    const location = useLocation();
    const isLandingPage = location.pathname === '/';
    const isSignUpPage = location.pathname === '/signup';
    const isLoginPage = location.pathname === '/login';
    const isDashboard = location.pathname === '/student/dashboard';
    const isViewCoursePage = location.pathname.startsWith('/course/'); // Check if the current path starts with '/course/'

    if (!isLandingPage  && !isLoginPage && !isDashboard) {
        return null; // If it's not the landing, signup, or login page, don't render the bottom div
    }

    if (isLandingPage  || isLoginPage) {

    }
    return (
        <div className="bottomDiv">
            < div className="firstBottomDiv" >
                <div id="bfdt">
                    <p id="tsh"> Techies Skill Hub</p>
                    <img src={at} alt="" /><span>2023 TSH - All Rights Reserved</span>
                </div>


                <div id="lbd2">
                    <p id="os">LG.</p>
                    <div id="lgp">
                        <p id="pob">  P. O. Box 235,</p>
                        <p id="ike"> ikeja, lagos State</p>
                        <p id="ths">tshub@gmail.com</p>
                    </div>
                </div>
            </div >

            <div className="lastBottomDiv" id="lastBottomDiv">

                <div id="pt" >
                    <span> <span id="prpo">Privacy Policy</span> <span id="vline">|</span> <span id="tou">Terms of use</span></span>
                </div>

                <div className="socials" id="socials">
  <span id="fs">Follow us: </span>
  <a id="simg" href='https://web.facebook.com/profile.php?id=61553473360496' target="_blank" rel="noopener noreferrer"><img src={fbs} alt="Instagram" /></a>
  <a id="simg" href='https://x.com/TechiesSkillHub' target="_blank" rel="noopener noreferrer"><img src={x} alt="Other" /></a>
  <a id="simg" href="https://www.linkedin.com/company/103188427" target="_blank" rel="noopener noreferrer"><img src={ig} alt="LinkedIn" /></a>
  <a id="simg" href='https://www.instagram.com/techiesskillhub/' target="_blank" rel="noopener noreferrer"><img src={sin} alt="Facebook" /></a>
</div>

            </div>
        </div >
    );
};

export default BottomDiv;
(
    <div className="bottomDiv">
        <div className="firstBottomDiv">
            <div id="bfdt">
                <span id="tsh"> Techies Skill Hub</span> <br />
                <img src={at} alt="" /><span>2023 TSH - All Rights Reserved</span>
            </div>


            <div id="lbd2">
                <span id="os">LG.</span> <br />
                <span id="lgp">
                    <span id="pob">  P. O. Box 235,</span><br />
                    <span id="ike"> ikeja, lagos State</span><br />
                    <span id="ths">tshub@gmail.com</span>
                </span>
            </div>
        </div>

        <div className="lastBottomDiv" id="lastBottomDiv">

            <span id="pt" >
                <span> <span id="prpo">Privacy Policy</span> <span id="vline">|</span> <span id="tou">Terms of use</span></span>
            </span>

            <span className="socials" id="socials">
                <span id="fs">Follow us: </span> <span id="simg" ><img src={ig} alt="" /></span>
                <span id="simg"><img src={x} alt="" /></span> <span id="simg"> <img src={sin} alt="" /> </span>
                <span id="simg"> <img src={fbs} alt="" /> </span>
            </span>

        </div>
    </div>
);