import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import BaseUrl from './BaseUrl';
import '../styles/ReviewSubmission.css';

import { toast, ToastContainer } from 'react-toastify';

import { useParams, useNavigate } from 'react-router-dom';

const ReviewSubmissionPage = () => {
    const { submissionId } = useParams();
    const [submissionData, setSubmissionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [scores, setScores] = useState([]);
    const [comment, setComment] = useState('');
    const [improvementSuggestion, setImprovementSuggestion] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubmissionData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${BaseUrl}/api/v1/task/submission/${submissionId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                setSubmissionData(response.data);
                setLoading(false);
                // Initialize scores state with default scores from grading rubric
                const initialScores = response.data.Task.GradingRubrics[0].Criteria.map(criterion => ({
                    criteria_id: criterion.id,
                    reviewer_score: criterion.score
                }));
                setScores(initialScores);
            } catch (error) {
                console.error('Error fetching submission data:', error);
                setLoading(false);
                if (error.response && error.response.status === 404) {
                    // Submission data not found, display appropriate message
                    toast.error('Submission data not found.');
                } else {
                    // Other errors, show generic error message
                    toast.error('Failed to fetch submission data. Please try again later.');
                }
            }
        };

        fetchSubmissionData();
    }, [submissionId]);
    const handleKeyDown = (event) => {
        if (event.code === 'PrintScreen' || (event.ctrlKey && event.code === 'KeyC') || (event.metaKey && event.code === 'KeyC')) {
          event.preventDefault();
          alert('Screenshots are not allowed.');
        }
      };
      
    
      useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    
    const handleOpenSubmission = () => {
        // Open the submission_file_path link in a new tab
        window.open(submissionData.submission_file_path, '_blank');
    };

    const handleSubmitReview = async () => {
        // Check if any score input is empty
        const isAnyScoreEmpty = scores.some(score => !score.reviewer_score);

        // If any score input is empty, prevent submission
        if (isAnyScoreEmpty) {
            toast.error('Please provide scores for all criteria.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const reviewData = {
                score: scores,
                comment,
                improvement_suggestion: improvementSuggestion,
                student_id: submissionData.student_id,
                task_id: submissionData.task_id
            };
            const response = await axios.post(`${BaseUrl}/api/v1/task/submit-review`, reviewData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 201) {
                // Show success toast
                toast.success('Review submitted successfully!');

                // Delay navigation for 3 seconds
                setTimeout(() => {
                    navigate('/assigned-reviews');
                }, 3000)
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            // Show error toast
            toast.error(error.response.data.error);
        }
    };


    const handleScoreChange = (criteriaId, value) => {
        // Find the criteria object corresponding to the provided criteria ID
        const criteria = submissionData.Task.GradingRubrics[0].Criteria.find(criterion => criterion.id === criteriaId);

        // Check if the entered value exceeds the criteria score
        if (value > criteria.score) {
            // If the entered value exceeds the criteria score, set the score to the criteria score
            setScores(prevScores =>
                prevScores.map(score =>
                    score.criteria_id === criteriaId ? { ...score, reviewer_score: criteria.score } : score
                )
            );
        } else {
            // If the entered value does not exceed the criteria score, update the score state
            setScores(prevScores =>
                prevScores.map(score =>
                    score.criteria_id === criteriaId ? { ...score, reviewer_score: value } : score
                )
            );
        }
    };

    // Check if all score inputs are empty
    const areAllScoresEmpty = scores.every(score => score.reviewer_score === '');

    return (
        <div className="container">
            <h2 className="section-heading">Review Submission Page</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                submissionData ? (
                    <Fragment>
                        <div className="rubric-section">
                            <h3 className="section-heading">Task Details</h3>
                            <p><strong>Instruction:</strong> {submissionData.Task.instructions}</p>
                            <p><strong>Description:</strong> {submissionData.Task.description}</p>
                            <p><strong>Required Submission Type:</strong> {submissionData.Task.requiredSubmissionType}</p>
                            <p><strong>Submission Instructions:</strong> {submissionData.Task.submission_instructions}</p>
                        </div>

                        <div className="rubric-section">
                            <h3 className="section-heading">Grading Rubric</h3>
                            <ul className="criteria-list">
                                {submissionData.Task.GradingRubrics[0].Criteria.map(criterion => (
                                    <li key={criterion.id} className="criteria-item">
                                        <strong>{criterion.name}:</strong> {criterion.description} (Weightage: {criterion.weightage}%)
                                        <input className="criteria-slider"
                                            type="range" /* Use input type "range" */
                                            min="0" /* Minimum value */
                                            max={criterion.weightage} /* Maximum value based on rubric weightage */
                                            value={scores.find(score => score.criteria_id === criterion.id)?.reviewer_score || 0} /* Set the value based on the score */
                                            onChange={e => handleScoreChange(criterion.id, parseInt(e.target.value))} /* Handle score change */
                                        />
                                        <span>{scores.find(score => score.criteria_id === criterion.id)?.reviewer_score || 0}</span> {/* Display the selected score */}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="review-details-section">
                            <h3 className="section-heading">Sample Solution</h3>
                            <p>Description: {submissionData.Project.description}</p>
                            <p>Objectives: {submissionData.Project.objectives}</p>
                        </div>

                        <div className="review-details-section">
                            <label htmlFor="comment">Comment:</label>
                            <textarea
                                id="comment"
                                className="textarea-input"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                required
                            />
                        </div>

                        <div className="review-details-section">
                            <label htmlFor="improvementSuggestion">Improvement Suggestion:</label>
                            <textarea
                                id="improvementSuggestion"
                                className="textarea-input"
                                value={improvementSuggestion}
                                onChange={(e) => setImprovementSuggestion(e.target.value)}
                            />
                        </div>

                        <button className="RSbutton" onClick={handleSubmitReview} disabled={areAllScoresEmpty}>Submit Review</button>
                        <button className="RSbutton" onClick={handleOpenSubmission}>Open Submission</button>
                    </Fragment>
                ) : (
                    <p>Submission data not found.</p>
                )
            )}
            <ToastContainer position="top-center" />
        </div>
    );
};

export default ReviewSubmissionPage;
