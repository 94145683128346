import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from './BaseUrl';

const SubmissionModal = ({ quizId, closeModal }) => {
    const [submissionData, setSubmissionData] = useState(null);

    useEffect(() => {
        const fetchSubmissionData = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${BaseUrl}/api/v1/quiz/submission/${quizId}`, config);
                setSubmissionData(response.data);
            } catch (error) {
                console.error('Error fetching submission data:', error);
            }
        };

        fetchSubmissionData();
    }, [quizId]);

    return (
        <div>
            <h2>Submission Details</h2>
            {submissionData ? (
                <div>
                    <h3>Quiz Title: {submissionData.quizTitle}</h3> {/* Display quiz title */}

                    <h3>Selected Options:</h3>
                    {submissionData.questions.map(question => (
                        <div key={question.id}>

                            <h4>Question Title: {question.title}</h4> {/* Display question title */}
                            <ul>
                                {question.options.map(option => (
                                    <li key={option.id}>
                                        {option.text} - Selected: {option.selected ? 'Yes' : 'No'}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <div>Loading submission data...</div>
            )}
            <button onClick={closeModal}>Close</button>
        </div>
    );
};

export default SubmissionModal;
