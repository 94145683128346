import LoginNavBar from './LoginNavBar';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../styles/CourseView.css';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';

const CourseViewAdmin = () => {
    const navigate = useNavigate();

    const [course, setCourse] = useState(null);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const { courseId } = useParams();

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${global.baseUrl}/api/v1/course/${courseId}`, config);
                setCourse(response.data.course);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching course:', error);
                setLoading(false);
            }
        };

        const fetchProjects = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${global.baseUrl}/api/v1/course/projects/${courseId}`, config);
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        if (courseId) {
            fetchCourse();
            fetchProjects();
        } else {
            setLoading(false);
        }
    }, [courseId]);

    const [expandedProjects, setExpandedProjects] = useState([]);

    const toggleProject = (projectId) => {
        setExpandedProjects(prevState => {
            if (prevState.includes(projectId)) {
                return prevState.filter(id => id !== projectId);
            } else {
                return [...prevState, projectId];
            }
        });
    };
    const handleCreateProject = (courseId) => {
        navigate(`/admin/create-project/${courseId}`);
    };
    const handleViewProject = (projectId) => {
        navigate(`/admin/project-view/${projectId}`);
    };

    const handleShowMore = (projectId) => {
        toggleProject(projectId);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!course) {
        return <div>Course not found</div>;
    }

    return (
        <div className="course-container">
            <button id="button" onClick={() => handleCreateProject(courseId)} className='register-link'>Create Project</button>

            <div className="course-info-section">
                <div className='titleDescription'>
                    <h1 className="course-title"><b>Course:</b> <br /> {course.title}</h1>
                </div>
                <div className='description'>
                    <h1 className="course-description"><b>Description </b><br />{course.description}</h1>
                </div>

                <div className="course-info">


                    <div>
                        <h1><b>More Info:</b></h1>
                        <p>{course.moreInfo}</p>
                        <p><b>Level: </b>{course.level}</p>
                    </div>


                </div>
            </div>
            <div className='course-project-section'>
                <div className='list'>
                    <h2 ><b>Projects for this course:</b></h2>
                    <ul className="project-list">
                        {projects.map(project => (
                            <li className="project-item" key={project.id}>
                                <h3 onClick={() => handleViewProject(project.id)} className="project-title">{project.week ? `Week ${project.week}` : project.title}</h3>
                                <div className="project-details">
                                    <p>Title: {project.title}</p>
                                    <p>Description: {expandedProjects.includes(project.id) ? project.description : `${project.description.slice(0, 100)}...`}</p>
                                    {project.description.length > 100 && (
                                        <button className="show-more-button" onClick={() => handleShowMore(project.id)}>
                                            {expandedProjects.includes(project.id) ? "Show Less" : "Show More"}
                                        </button>
                                    )}
                                    <p>Posted By: {project.postedBy}</p>
                                    <p>Average Score: {project.averageScore}</p>
                                </div>
                                <button className="view-project-button" onClick={() => handleViewProject(project.id)}>View Project</button>

                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div >
    );

};

export default CourseViewAdmin;
