import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BaseUrl from './BaseUrl';

const AdminProfile = () => {
    const navigate = useNavigate();
    const [adminProfile, setAdminProfile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    useEffect(() => {
        const fetchAdminProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BaseUrl}`+'/api/v1/user/admin-profile', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setAdminProfile(response.data.admin);
                    setProfileImage(response.data.admin.profileImage);
                } else {
                    console.error('Failed to fetch admin profile');
                }
            } catch (error) {
                console.error('Error fetching admin profile:', error);
            }
        };

        fetchAdminProfile();
    }, []);

    // Logout handler
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        navigate('/login');
    };
    const handleDeleteAccount = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const email = user.email;
            console.log({ user, email });
            if (email) {
                // Show confirmation dialog
                const confirmed = window.confirm("Are you sure you want to delete your account?");
                if (confirmed) {
                    const response = await axios.post(`${BaseUrl}`+'/api/v1/user/delete-account', { email });
                    if (response.status === 200) {
                        toast.success(response.data.message, { position: 'top-center' });
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('user');
                        navigate('/login');
                    } else {
                        toast.error(response.data, { position: 'top-center' });
                    }
                }
            }
        } catch (error) {
            console.error('Error deleting account:', error.response);
            if (error.response && error.response.data && error.response.data) {
                toast.error(error.response.data, { position: 'top-center' });
            } else {
                toast.error('Failed to delete account. Please try again later.', { position: 'top-center' });
            }
        }
    };

    // Change password handler
    const handleChangePassword = () => {
        // Navigate to the change password page
        navigate('/change-password');
    };

    // Upload profile image handler
    const handleUploadImage = async () => {
        if (!selectedFile) {
            toast.error('Please select an image to upload');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            setUploading(true);
            const response = await axios.post(`${BaseUrl}`+'/api/v1/user/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                setProfileImage(response.data.imageUrl);

                toast.success('Profile image updated successfully');
                setSelectedFile(null);
            } else {
                console.error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const renderProfileImage = () => {
        if (profileImage) {
            return <img src={profileImage} alt="Profile" style={{ width: '200px', borderRadius: '50%' }} />;
        } else {
            const initials = adminProfile.firstName.charAt(0) + adminProfile.lastName.charAt(0);
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            const style = {
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                background: `#${randomColor}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '50px'
            };
            return <div style={style}>{initials}</div>;
        }
    };

    return (
        <div>
            {adminProfile && (
                <div>
                    <h1>Admin Profile</h1>
                    <p>First Name: {adminProfile.firstName}</p>
                    <p>Last Name: {adminProfile.lastName}</p>
                    <p>Email: {adminProfile.email}</p>

                    {renderProfileImage()}
                    <h2>Update Profile Image</h2>
                    <input type="file" onChange={handleFileChange} accept="image/*" />
                    <button onClick={handleUploadImage}>Upload Image</button>
                    {uploading && <p>Uploading image...</p>}
                    <br></br>
                    <br></br>
                    <h2>Account</h2>

                    <button onClick={handleLogout}>Logout</button>
                    <button onClick={handleDeleteAccount}>Delete Account</button>
                    <button onClick={handleChangePassword}>Change Password</button>
                </div>
            )}
            {!adminProfile && <p>Loading admin profile...</p>}
        </div>
    );
};

export default AdminProfile;
