import React, { useState, useEffect } from 'react';
import '../styles/SignUp.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import BaseUrl from './BaseUrl';
import Select from 'react-select'; // Import react-select
import keyboard from '../assests/images/keyboard.jpg';
import texture from '../assests/images/texture.jpg';

const baseUrl = 'https://tshub.torh.tech';

const SignUp = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sexes, setSex] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedSex, setSelectedSex] = useState('');
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    let responseMessage = '';

    const handleOkayButtonClick = (role) => {
        if (role === 'ADMIN' || role === 'INSTRUCTOR') {
            navigate('/admin/dashboard')
        } else if (role === 'STUDENT') {
            navigate('/student/dashboard/fe')
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post(baseUrl + '/api/v1/user/register-student', {
                firstName,
                lastName,
                email,
                phoneNumber,
                password,
                sex: selectedSex,
                country: selectedCountry
            });
            const renderCustomToast = () => (
                <div>
                    <p>Account registered successfully! {response.data.message}</p>
                    <button onClick={() => handleOkayButtonClick(response.data.user.role)}>Okay</button>
                </div>
            );
            if (response.status === 201) {
                const token = response.data.token;
                const user = response.data.user;
                localStorage.setItem('token', token);
                localStorage.setItem('userId', response.data.id);
                localStorage.setItem('user', JSON.stringify(user));
                const role = response.data.user.role;
                if (role === 'ADMIN' || role === 'INSTRUCTOR') {
                    navigate('/admin/dashboard')
                } else if (role === 'STUDENT') {
                    navigate('/student/dashboard/fe')
                }
               toast.success(renderCustomToast(), { position: 'top-right', autoClose: false });
            } else {
                toast.error(response.data.message, { position: 'top-right' });
            }
        } catch (error) {
            const responseMessage = error.response ? error.response.data : 'Registration Failed';
            toast.error(responseMessage, { position: 'top-right' });
            console.error('Error:', error.message);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        const getSex = async () => {
            try {
                const response = await axios.get(BaseUrl + '/api/v1/user/sex');
                const sexArray = response.data.roles.map(role => ({
                    id: role.id,
                    label: role.sex
                }));
                setSex(sexArray);
            } catch (error) {
                console.error('Error fetching sex:', error);
            }
        }

        getSex();
    }, []);

    useEffect(() => {
        const getCountry = async () => {
            try {
                const response = await axios.get(BaseUrl + '/api/v1/user/countries');
                const countries = response.data.countries.map(country => ({ id: country.id, label: country.country }));

                setCountries(countries);
            } catch (error) {
                console.error('Error fetching Country:', error);
            }
        }
        getCountry();
    }, []);

    return (
        <div className='signUpScreenContainer'>
         <div className='keyboardDiv'> <img className='keyboardSignup' src={keyboard} alt="" /></div>  
         <div className='overLay'></div>  
        <div className="formDiv">
            
              <form onSubmit={handleSubmit}>
                <h2 style={{ fontSize:"2rem", fontWeight:"bold" }}>  Sign Up for an account</h2> <br />
                <input type="text" placeholder='FirstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} required /><br />
                <input type="text" placeholder='LastName' value={lastName} onChange={(e) => setLastName(e.target.value)} required /><br />
                <input type="text" placeholder='E-mail address' value={email} onChange={(e) => setEmail(e.target.value)} required /><br />
                <input type="text" placeholder='PhoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required /><br /><br />
                <input type="password" placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
              <div className="selectDiv">
              <Select
                    value={sexes.find(s => s.id === selectedSex)}
                    onChange={(option) => setSelectedSex(option ? option.id : '')}
                    options={sexes}
                    placeholder="Select Sex"
                /><br />
                <Select
                    value={countries.find(c => c.id === selectedCountry)}
                    onChange={(option) => setSelectedCountry(option ? option.id : '')}
                    options={countries}
                    placeholder="Select Country"
                /><br />
              </div>
               

                <p className='TermsText'>By signing up, you confirm that you've read and accepted our Term of use and Privacy Policy</p>
                <button type="submit" disabled={isSubmitting} id='signUpSigningUp'>{isSubmitting ? 'Signing Up...' : 'Sign Up'}</button> <br />
                <p id='aha' onClick={() => setTimeout(() => navigate('/login'), 1000)}>Already have an account? Log in</p>
            </form></div>
          

        </div>
    );
};

export default SignUp;
