import React, { useState } from "react";
import "../styles/ProjectUpload.css";

const ProjectUpload = () => {
  const [title, setTitle] = useState("");
  const [postedBy, setPostedBy] = useState("");
  const [description, setDescription] = useState("");
  const [concepts, setConcepts] = useState([{ name: "", videoLink: "" }]);
  const [resources, setResources] = useState([{ name: "", link: "" }]);
  const [objectives, setObjectives] = useState([{ name: "", link: "" }]);
  const [moreInfo, setMoreInfo] = useState("");

  const handleConceptChange = (index, field, value) => {
    const updatedConcepts = [...concepts];
    updatedConcepts[index][field] = value;
    setConcepts(updatedConcepts);
  };

  const handleResourceChange = (index, field, value) => {
    const updatedResources = [...resources];
    updatedResources[index][field] = value;
    setResources(updatedResources);
  };

  const handleObjectiveChange = (index, field, value) => {
    const updatedObjectives = [...objectives];
    updatedObjectives[index][field] = value;
    setObjectives(updatedObjectives);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="project-upload-container unique-project-upload">
      <h1>Upload Project</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <label htmlFor="description">Description:</label>
        <textarea
          id="descri"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />

        <label htmlFor="concepts">Concepts:</label>
        {concepts.map((concept, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder="Concept name"
              value={concept.name}
              onChange={(e) => handleConceptChange(index, "name", e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Video link"
              value={concept.videoLink}
              onChange={(e) => handleConceptChange(index, "videoLink", e.target.value)}
              required
            />
          </div>
        ))}
        <button type="button" onClick={() => setConcepts([...concepts, { name: "", videoLink: "" }])}>
          Add Concept
        </button>

        <label htmlFor="resources">Resources:</label>
        {resources.map((resource, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder="Resource name"
              value={resource.name}
              onChange={(e) => handleResourceChange(index, "name", e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Link"
              value={resource.link}
              onChange={(e) => handleResourceChange(index, "link", e.target.value)}
              required
            />
          </div>
        ))}
        <button type="button" onClick={() => setResources([...resources, { name: "", link: "" }])}>
          Add Resource
        </button>

        <label htmlFor="objectives">Objectives:</label>
        {objectives.map((objective, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder="Objective name"
              value={objective.name}
              onChange={(e) => handleObjectiveChange(index, "name", e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Link"
              value={objective.link}
              onChange={(e) => handleObjectiveChange(index, "link", e.target.value)}
              required
            />
          </div>
        ))}
        <button type="button" onClick={() => setObjectives([...objectives, { name: "", link: "" }])}>
          Add Objective
        </button>

        <label htmlFor="moreInfo">More Info:</label>
        <textarea
          id="moreInfo"
          value={moreInfo}
          onChange={(e) => setMoreInfo(e.target.value)}
        />

        <button type="submit">Upload Project</button>
      </form>
    </div>
  );
};

export default ProjectUpload;
