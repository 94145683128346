import React, { useState } from 'react';
import '../styles/Login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://52.204.2.95';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSigningIn(true);
        try {
            const response = await axios.post(`${baseUrl}/api/v1/user/login`, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success('Welcome Back!', { position: 'top-right' });
                const token = response.data.token;
                localStorage.setItem('token', token);
                localStorage.setItem('userId', response.data.id);
                const user = response.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                const role = user.role;
                console.log({ response })
                const isNewPasword = response.data.isNewPassword
                setTimeout(() => {
                    if (isNewPasword) {
                        navigate(`/change-password/${isNewPasword}`)
                    } else
                        if (role === 'ADMIN' || role === 'INSTRUCTOR') {
                            navigate('/admin/dashboard');
                        } else if (role === 'STUDENT') {
                            navigate('/student/dashboard/fe');
                        }
                }, 1000);
            } else {
                console.log(response);
            }

        } catch (error) {
            console.log(error.message, error);
            toast.error(error.response.data, { position: 'top-right' });
        }
        setIsSigningIn(false);
    };

    const navigateToSignUp = () => {
        setTimeout(() => {
            navigate('/signup');
        }, 1000);
    };
    const navigateToResetPassword = () => {
        setTimeout(() => {
            navigate('/reset-password');
        }, 1000);
    };

    return (
        <div className="screenContainer">
            <center>
                <div className='loginPage'>
                    <div className='form'>
                        <h1>Login</h1>
                        <form onSubmit={handleSubmit}>
                            <input className='loginInput' type="email" placeholder='E-mail Address' value={email} onChange={(e) => setEmail(e.target.value)} required /><br /><br />
                            <input className='loginInput' type="password" placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <p onClick={navigateToResetPassword}>Forgot password?</p> <br />
                            <button className='loginButton' type="submit" disabled={isSigningIn}>{isSigningIn ? "Signing in..." : "Sign In"}</button>
                            <p id='dha' onClick={navigateToSignUp}>Don't have an account yet? Register</p>
                        </form>
                    </div>
                </div>
            </center>
            <ToastContainer />
        </div>
    );
};

export default Login;
