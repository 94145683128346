import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const ExploreCurriculumModal = ({ isOpen, onRequestClose }) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        onRequestClose();
        navigate('/login');
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    width: '400px',
                    margin: 'auto',
                    borderRadius: '10px',
                    padding: '20px'
                }
            }}
        >
            <h2>To explore the curriculum, you need to sign up or log in.</h2>
            <p>Do you want to proceed?</p>
            <div style={buttonContainer}>
                <button style={cancelButton} onClick={onRequestClose}>Cancel</button>
                <button style={loginButton} onClick={handleLogin}>Login</button>
            </div>
        </Modal>
    );
};

export default ExploreCurriculumModal;


const buttonContainer = {
    display: 'flex',
    justifyContent: 'space-around'
};

const cancelButton = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
};

const loginButton = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginLeft: '10px'
};

cancelButton[':hover'] = {
    backgroundColor: '#167735'
};

loginButton[':hover'] = {
    backgroundColor: '#167735'
};
