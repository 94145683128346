import React, { useState } from "react";
import "../styles/AddInstructor.css";
import defaultImage from '../assests/images/download.jpg'

const AddInstructor = () => {
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  return (
    <div className="container">
      <div className="split-container">
        <div className="form-container">
          <h2 className="form-title">Add instructor</h2>
          <form>
            <div className="form-group">
              <label htmlFor="firstname" id="fname">Firstname</label>
              <input id="firstname" />
            </div>
            <div className="form-group">
              <label htmlFor="lastname" id="lname">Lastname</label>
              <input id="lastname" />
            </div>
            <div className="form-group">
              <label htmlFor="email" id="em">Email</label>
              <input className='email' type="email" id="email" />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber" id="pnumber">PhoneNumber</label>
              <input type="tel" id="phoneNumber" />
            </div>
            <div className="form-group">
              <label htmlFor="course">Course</label>
              <select id="course">
                <option value="java">Java</option>
                <option value="javascript">JavaScript</option>
                <option value="react">React</option>
                <option value="html-css">HTML & CSS</option>
                <option value="python">Python</option>
              </select>
            </div>
            <div className="form-group">
              <button className="submitButton" type="submit" id="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="image-container">
          <h2>Select Profile Image</h2>
          <label htmlFor="imageInput" className="image-label">
            Click to select an image
          </label>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <div className="image-preview">
            {image ? (
              <img className="image-preview" src={URL.createObjectURL(image)} alt="Selected" />
            ) : (
              <div className="placeholder">
                <img className="image-preview" src={defaultImage} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInstructor;
