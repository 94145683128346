import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import BaseUrl from './BaseUrl';

const BlogManagement = () => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BaseUrl}/api/v1/blog`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setBlogs(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setIsLoading(false);
        }
    };

    const openModal = (blog) => {
        setSelectedBlog(blog);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBlog(null);
    };

    const handleUpdateBlog = async () => {
        // Implement blog update logic here
        console.log('Updating blog:', selectedBlog);
        closeModal();
    };

    return (
        <div>
            <h2>Blog Management</h2>
            <div>
                <h3>Create New Blog</h3>
                {/* Input fields for creating a new blog */}
            </div>
            <div>
                <h3>Existing Blogs</h3>
                {/* Display existing blogs */}
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div>
                        {blogs.map((blog) => (
                            <div key={blog.id}>
                                <Link to={`/blogs/${blog.id}`}>{blog.title}</Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Edit Blog</h2>
                        {/* Input fields for editing the selected blog */}
                        <button onClick={handleUpdateBlog}>Update</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogManagement;
