import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ScheduleView = () => {
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        const fetchSchedules = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${global.baseUrl}/api/v1/student/schedules`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setSchedules(response.data.schedules);
                }
            } catch (error) {
                console.error('Error fetching schedules:', error);
            }
        };
        fetchSchedules();
    }, []);

    const handleEventClick = (event) => {
        // Check if the event has projectId
        if (event.projectId) {
            // Navigate to view project by its ID
            navigate(`/project-view/${event.projectId}`);
        }
    };

    return (

        <div className='schedule'  >
            <h1><b>My Schedule</b></h1>
            <Calendar
                localizer={localizer}
                events={schedules.map(schedule => (
                    schedule.schedule.map(event => ({
                        title: event.title,
                        start: new Date(event.date),
                        end: new Date(event.date),
                        description: event.description,
                        projectId: event.projectId // Include projectId in the event object
                    }))
                )).flat()}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, }}
                onSelectEvent={handleEventClick} // Call handleEventClick when event is clicked
            />
        </div>
    );
};

export default ScheduleView;
