import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClock, faGraduationCap, faUserCheck, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import adminImage from '../assests/images/adminProfile.jpg';
import { Link } from 'react-router-dom'; // Import Link for routing
import '../styles/AdminDashboard.css';

const AdminDashboard = () => {
    return (
        <div>
            <div className="adtopNav">
                <h1>Admin</h1>
                <FontAwesomeIcon id='bell' icon={faBell} />
                <img src={adminImage} alt="" />
            </div>

            <div className='centerDiv'>
                <div id='adiconDiv'>
                    <span id='adiconbg'><FontAwesomeIcon id='adicon' icon={faUserGroup} /></span>
                    <span>
                        <span><h1>1020</h1></span>
                        <span id='adiconText'>Total cohorts</span>
                    </span>
                </div>

                <div id='adiconDiv'>
                    <span id='adiconbg2'><FontAwesomeIcon id='adicon2' icon={faGraduationCap} /></span>
                    <span>
                        <span><h1>459</h1></span>
                        <span id='adiconText'>Graduated </span>
                    </span>
                </div>

                <div id='adiconDiv'>
                    <span id='adiconbg2'><FontAwesomeIcon id='adicon2' icon={faClock} /></span>
                    <span>
                        <span><h1>561</h1></span>
                        <span id='adiconText'>Ongoing </span>
                    </span>
                </div>
            </div>

            <div className='recentActivities'>
                <div className='ra'>
                    <h2>Recent Activities</h2>
                </div>

                <div className='td'>

                    <Link to="/student/dashboard/fe" className="view-student-db-button">View Student DashBoard</Link>
                </div>
            </div>
        </div>
    );
}

export default AdminDashboard;
