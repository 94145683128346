import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import BaseUrl from './BaseUrl';
import '../styles/ProjectView.css';
import Modal from 'react-modal';
import QuizModal from './QuizModal';
import SubmissionModal from './SubmissionModal';
import { toast, ToastContainer } from 'react-toastify';
import LoginNavBar from './LoginNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faFile, faPenSquare, faCheckCircle, faTimesCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ProjectViewAdmin = () => {
    const navigate = useNavigate();

    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [selectedOptionsIds, setSelectedOptionsIds] = useState(Array(0));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [quizData, setQuizData] = useState(null);
    const [submittedQuizzes, setSubmittedQuizzes] = useState([]);
    const [showSubmissionModal, setShowSubmissionModal] = useState(false);
    const [quizIdForSubmissionModal, setQuizIdForSubmissionModal] = useState(null);
    const [linkInput, setLinkInput] = useState('');
    const [fileInput, setFileInput] = useState(null);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${BaseUrl}/api/v1/project/getProject/${projectId}`, config);
                setProject(response.data.project);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project:', error);
                setLoading(false);
            }
        };

        fetchProject();
    }, [projectId]);

    const viewQuizSubmission = (quizId) => {
        setQuizIdForSubmissionModal(quizId);
        setShowSubmissionModal(true);
    };

    const closeSubmissionModal = () => {
        setShowSubmissionModal(false);
        setQuizIdForSubmissionModal(null);
    };

    const openQuizModal = async (quiz) => {
        if (showQuizModal) {
            setShowQuizModal(false);
        }
        setShowQuizModal(true);
        setCurrentQuestion(0);

        const shuffledQuestions = quiz.QuizQuestions.sort(() => Math.random() - 0.5);

        const shuffledQuestionsWithShuffledOptions = shuffledQuestions.map(question => ({
            ...question,
            QuizQuestionOptions: question.QuizQuestionOptions.sort(() => Math.random() - 0.5)
        }));

        setQuizData({ ...quiz, QuizQuestions: shuffledQuestionsWithShuffledOptions });
        setSelectedOptionsIds(Array(shuffledQuestionsWithShuffledOptions.length).fill(null));
    };

    const closeQuizModal = () => {
        setShowQuizModal(false);
        setCurrentQuestion(0);
    };

    const checkQuizSubmission = async (quizId) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await axios.get(`${BaseUrl}/api/v1/quiz/check-submission/${quizId}`, config);
            return response.data;
        } catch (error) {
            console.error('Error checking quiz submission:', error);
            return false;
        }
    };

    const attemptQuiz = async (quiz) => {
        setShowQuizModal(true);
        setCurrentQuestion(0);

        const shuffledQuestions = quiz.QuizQuestions.sort(() => Math.random() - 0.5);
        const shuffledQuestionsWithShuffledOptions = shuffledQuestions.map(question => ({
            ...question,
            QuizQuestionOptions: question.QuizQuestionOptions.sort(() => Math.random() - 0.5)
        }));

        setQuizData({ ...quiz, QuizQuestions: shuffledQuestionsWithShuffledOptions });
        setSelectedOptionsIds(Array(shuffledQuestionsWithShuffledOptions.length).fill(null));
    };

    const submitTask = async (taskId, submissionData, type) => {
        try {
            if (type === 'link') {
                if (!submissionData.link || submissionData.link.trim() === '') {
                    toast.error('Please provide a link for submission', { position: 'top-center', autoClose: 3000 });
                    return;
                }
            }

            const token = localStorage.getItem('token');
            const formData = new FormData();

            // Append task ID to form data
            formData.append('taskId', taskId);

            // Append submission data to form data
            Object.keys(submissionData).forEach(key => {
                formData.append(key, submissionData[key]);
            });

            // Append file to form data
            if (submissionData.file) {
                formData.append('file', submissionData.file);
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await axios.post(`${BaseUrl}/api/v1/task/submit-task/${taskId}`, formData, config);
            if (response.data === 'Submitted') {
                toast.success('Task submitted successfully!', { position: 'top-center', autoClose: 3000 });
            } else {
                if (response.status === 201) {
                    toast.success(response.data.message, { position: 'top-center', autoClose: 3000 });
                } else {
                    toast.error('Failed to submit task', { position: 'top-center', autoClose: 3000 });
                }
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            toast.error('' + error.response.data.error, { position: 'top-center', autoClose: 3000 });
        }
    };


    useEffect(() => {
        if (project) {
            const fetchSubmittedQuizzes = async () => {
                const submittedQuizzesPromises = project.Quizzes.map(async (quiz) => {
                    const submitted = await checkQuizSubmission(quiz.id);
                    return { quizId: quiz.id, submitted };
                });
                const submittedQuizzesData = await Promise.all(submittedQuizzesPromises);
                setSubmittedQuizzes(submittedQuizzesData);
            };

            fetchSubmittedQuizzes();
        }
    }, [project]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!project) {
        return <div>Project not found</div>;
    }
    const handleCreateTask = (projectId) => {
        navigate(`/admin/create-task/${projectId}`);
    };
    return (
        <div className='ProjectViewMainCont'>
            <button id="button" onClick={() => handleCreateTask(projectId)} className='register-link'>Create Task</button>


            <div className='ProjectViewContent' >
                <h1 >{project.title}</h1>
                <div className='ProjectInfo'>
                    <p> <strong>Description:</strong> {project.description}</p>
                    <p> <strong>Posted By:</strong> {project.postedBy}</p>
                    <p> <strong>Week:</strong> {project.week}</p>
                    <p> <strong>More Info:</strong> {project.moreInfo}</p>
                </div>

                <h2>Resources</h2>
                {project.ProjectResources && project.ProjectResources.length > 0 && (
                    <ul>
                        {project.ProjectResources.map((resource, index) => (
                            <li className='li' key={index}>
                                <strong className="PVTitle">{resource.title}<br /></strong>
                                <strong className="PVSub"></strong> {resource.sub_title}<br />
                                <strong className="PVDesc"></strong> {resource.description}<br />
                                <strong id='Url'>URL:</strong>
                                <a id='link' href={resource.url} target="_blank" rel="noopener noreferrer">
                                    {resource.url} <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                            </li>
                        ))}
                    </ul>
                )}

                <h2>Quizzes</h2>
                {project.Quizzes.map(quiz => (
                    <div key={quiz.id}>
                        <h3>{quiz.title}</h3>
                        <p>{quiz.description}</p>

                        {/* Render buttons based on quiz submission status */}
                        {submittedQuizzes.some(submittedQuiz => submittedQuiz.quizId === quiz.id && submittedQuiz.submitted) ? (
                            <>
                                <button onClick={() => attemptQuiz(quiz)}>
                                    Re-attempt Quiz <FontAwesomeIcon icon={faPenSquare} />
                                </button>
                                <button onClick={() => viewQuizSubmission(quiz.id)}>
                                    View Your Attempt <FontAwesomeIcon icon={faCheckCircle} />
                                </button>
                            </>
                        ) : (
                            <button onClick={() => attemptQuiz(quiz)}>
                                Attempt Quiz <FontAwesomeIcon icon={faPenSquare} />
                            </button>
                        )}
                    </div>
                ))}

                {/* Submission modal rendering... */}
                <Modal
                    isOpen={showSubmissionModal}
                    onRequestClose={closeSubmissionModal}
                    contentLabel="Submission Modal"
                >
                    <SubmissionModal quizId={quizIdForSubmissionModal} closeModal={closeSubmissionModal} />
                </Modal>

                <h2 >Tasks</h2>
                {project.tasks && project.tasks.length > 0 && (
                    <ul className='PVTasks'>
                        {project.tasks.map((task, index) => (
                            <li key={index} style={{ backgroundColor: 'rgba(230, 230, 230, 0.3)', paddingTop: '10px', marginBottom: '20px', paddingBottom: '10px', border: '1px solid black' }}>

                                <strong className='PVTaskName'>{task.name}<br /></strong>
                                <br />
                                <strong >Instructions:</strong>
                                <div dangerouslySetInnerHTML={{ __html: task.instructions }} />
                                <br /><br />
                                <strong>Submission Instructions:</strong><div dangerouslySetInnerHTML={{ __html: task.submission_instructions }} />
                                <br /><br />
                                <strong>Required Submission Type:</strong> {task.requiredSubmissionType}<br /><br />

                                {task.requiredSubmissionType === 'link' && (
                                    <>
                                        <input type="text" placeholder="Enter link" value={linkInput} onChange={(e) => setLinkInput(e.target.value)} />
                                        <button onClick={() => submitTask(task.id, { link: linkInput }, task.requiredSubmissionType)}>
                                            Submit Task <FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </button>
                                    </>
                                )}

                                {task.requiredSubmissionType === 'file' && (
                                    <>
                                        <input type="file" name="file" onChange={(e) => setFileInput(e.target.files[0])} />
                                        <button onClick={() => submitTask(task.id, { file: fileInput }, task.requiredSubmissionType)}>
                                            Submit Task <FontAwesomeIcon icon={faFile} />
                                        </button>
                                    </>
                                )}

                                {task.requiredSubmissionType === 'github' && (
                                    <>
                                        <p>Submit your solution on GitHub repository: {task.solution_repo_name}</p>
                                        <p>File path on Github: {task.solution_file_path}</p>
                                        <button onClick={() => submitTask(task.id, { repository: task.repository, file: task.solution_file_path }, task.requiredSubmissionType)}>
                                            Submit Task <FontAwesomeIcon icon={faExternalLinkAlt} />
                                        </button>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                )}

                <Modal
                    isOpen={showQuizModal}
                    onRequestClose={closeQuizModal}
                    contentLabel="Quiz Modal"
                >
                    {quizData && (
                        <QuizModal
                            quizData={quizData}
                            selectedOptionsIds={selectedOptionsIds}
                            setCurrentQuestion={setCurrentQuestion}
                            setSelectedOptionsIds={setSelectedOptionsIds}
                            closeModal={closeQuizModal}
                        />
                    )}
                </Modal>

                <ToastContainer position="top-center" />
            </div>
        </div>
    );
};

export default ProjectViewAdmin;
