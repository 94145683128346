import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavBackButton = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back one step
    };

    return (
        <button id="NavbackButton" className='NavbackButton' onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></button>
    );
};

export default NavBackButton;
