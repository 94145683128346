import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import BaseUrl from './BaseUrl';

const AllCoursesView = () => {
    const [courses, setCourses] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCohort, setSelectedCohort] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://52.204.2.95/api/v1/course', { headers: { Authorization: `Bearer ${token}` } });
                setCourses(response.data.Courses);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setLoading(false);
                toast.error('Failed to fetch courses. Please try again.');
            }
        };

        fetchCourses();
    }, []);

    const fetchCohorts = async (courseId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://52.204.2.95/api/v1/cohort/course/${courseId}`, { headers: { Authorization: `Bearer ${token}` } });
            setCohorts(response.data.cohort);
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error fetching cohorts:', error);
            toast.error('Failed to fetch cohorts. Please try again.');
        }
    };

    const handleRegister = async () => {
        if (!selectedCohort) {
            toast.error('Please select a cohort.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://52.204.2.95/api/v1/course/student',
                { course: selectedCourse, cohort: selectedCohort },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 201) {
                toast.success('Course Registration Successful');
                setModalIsOpen(false);
            } else {
                toast.error('Unexpected error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error registering course:', error);
            toast.error(error.response.data);
        }
    };

    return (
        <div className='container'>
            <header>
                <h1 className="title">All Courses</h1>
            </header>
            <main>
                <div className='all-course-list'>
                    {courses.map(course => (
                        <div className="course" key={course.id}>
                            <img src={course.image} alt={course.title} className="courseImage" />
                            <div className="course-details">
                                <h2 className="course-title">{course.title}</h2>
                                <p className="course-description">Description: {course.description}</p>
                                <p className="course-fee">Fee: {course.fee_currency} {course.fee}</p>
                                <button className="blueButton" onClick={() => { fetchCohorts(course.id); setSelectedCourse(course.id); }}>Register</button>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
    <h2>Select Cohort</h2>
    <select onChange={(e) => setSelectedCohort(e.target.value)}>
    <option value="">Select Cohort</option>
    {cohorts.filter(cohort => cohort.name !== null).map(cohort => (
        <option key={cohort.id} value={cohort.id}>{cohort.name}</option>
    ))}
</select>

    {selectedCohort && (
    <div>
        <p>Cohort Information:</p>
        <p>Name: {cohorts.find(cohort => cohort.id === parseInt(selectedCohort, 10)).name}</p>
        <p>Start Date: {cohorts.find(cohort => cohort.id === parseInt(selectedCohort, 10)).startDate}</p>
        <p>End Date: {cohorts.find(cohort => cohort.id === parseInt(selectedCohort, 10)).endDate}</p>
       
    </div>
)}

    <button className="blueButton" onClick={handleRegister}>Register</button>
</Modal>

            </main>
        </div>
    );
};

export default AllCoursesView;
