import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/UploadTask.css';

const UploadTask = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    instructions: '',
    script: '',
    repository: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toast.success('Task submitted successfully!');
      setTimeout(() => {
        navigate('/upload-tasks-and-quizzes/1');
      }, 2000);
    }, 2000);
  };

  return (
    <div className="upload-task-container">
      <ToastContainer />
      <form className="node-creation-form" onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <label>
          Type:
          <input type="text" name="type" value={formData.type} onChange={handleChange} required />
        </label>
        <label>
          Instructions:
          <textarea name="instructions" value={formData.instructions} onChange={handleChange} required />
        </label>
        <label>
          Script:
          <textarea name="script" value={formData.script} onChange={handleChange} required />
        </label>
        <label>
          Repository:
          <input placeholder='github url' type="text" name="repository" value={formData.repository} onChange={handleChange} disabled required />
        </label>
        <button  type="submit"  disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
      </form>
    </div>
  );
};

export default UploadTask;
