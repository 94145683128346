import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

const ChangePasswordView = () => {
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const { isNewPasword } = useParams();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            setEmail(parsedUser.email);
        }
    }, []);

    const handleChangePassword = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://tshub.torh.tech/api/v1/user/change-password', {
                email: email,
                oldPassword: oldPassword,
                newPassword: newPassword,
                isNewPasword: isNewPasword
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                toast.success(response.data.message, { position: 'top-center' });
            } else {
                toast.error(response.data.message, { position: 'top-center' });
            }
        } catch (error) {
            console.error('Error changing password:', error);
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, { position: 'top-center' });
            } else {
                toast.error('Failed to change password', { position: 'top-center' });
            }
        }
    };


    return (
        <div>
            <h2>Change Password</h2>
            <div>
                <input type="hidden" value={email} />
            </div>
            <div>
                <label>Old Password:</label>
                <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            </div>
            <div>
                <label>New Password:</label>
                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <button onClick={handleChangePassword}>Change Password</button>
            <ToastContainer position="top-center" />
        </div>
    );
};

export default ChangePasswordView;
