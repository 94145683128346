import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        navigate(-1); // Navigate back one step
    };

    // Check if location has a state indicating previous location
    const showBackButton = location.state && location.state.from;

    return showBackButton ? (
        <button id="backButton" className='backButton' onClick={goBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    ) : null;
};

export default BackButton;
