import BottomDiv from './BottomDiv';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/StudentProfile.css';

const StudentProfile = () => {
    const navigate = useNavigate();
    const [studentProfile, setStudentProfile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFullImage, setShowFullImage] = useState(false); // New state to control full image display

    // Fetch student profile data
    useEffect(() => {
        const fetchStudentProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://tshub.torh.tech/api/v1/user/student-profile', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setStudentProfile(response.data.student);
                    setProfileImage(response.data.student.profileImage);
                } else {
                    console.error('Failed to fetch student profile');
                }
            } catch (error) {
                console.error('Error fetching student profile:', error);
            }
        };

        fetchStudentProfile();
        window.scrollTo(0, 0);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleDeleteAccount = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const email = user.email;
            if (email) {
                const confirmed = window.confirm("Are you sure you want to delete your account?");
                if (confirmed) {
                    const response = await axios.post('https://tshub.torh.tech/api/v1/user/delete-account', { email });
                    if (response.status === 200) {
                        toast.success(response.data.message, { position: 'top-center' });
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('user');
                        navigate('/login');
                    } else {
                        toast.error(response.data, { position: 'top-center' });
                    }
                }
            }
        } catch (error) {
            console.error('Error deleting account:', error.response);
            if (error.response && error.response.data && error.response.data) {
                toast.error(error.response.data, { position: 'top-center' });
            } else {
                toast.error('Failed to delete account. Please try again later.', { position: 'top-center' });
            }
        }
    };

    const handleChangePassword = () => {
        navigate(`/change-password/${false}`)
    };

    const handleUpdateProfile = () => {
        navigate(`/update-student`);
    };

    const handleUploadImage = async () => {
        if (!selectedFile) {
            toast.error('Please select an image to upload');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            setUploading(true);
            const response = await axios.post('https://tshub.torh.tech/api/v1/user/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                setProfileImage(response.data.imageUrl);
                toast.success('Profile image updated successfully');
                setSelectedFile(null);
            } else {
                console.error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const renderProfileImage = () => {
        if (profileImage) {
            return (
                <img
                    className="profile-image"
                    src={profileImage}
                    alt="Profile"
                    onClick={() => setShowFullImage(!showFullImage)}
                />
            );
        } else {
            const initials = studentProfile.firstName.charAt(0) + studentProfile.lastName.charAt(0);
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            const style = {
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                background: `#${randomColor}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '50px'
            };
            return <div style={style}>{initials}</div>;
        }
    };

    return (
        <div className="student-profile-container">
            {studentProfile && (
                <div>
                    <div className="profile-image-section">
                        <div className="inner">
                            {renderProfileImage()}
                        </div>
                        <h1 className="student_name">{studentProfile.firstName} {studentProfile.lastName}</h1>
                        <br />
                        <div className="image-upload-section">
                            <h2><b>Click the button below to upload a new profile image</b> </h2>
                            <br />
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*"
                                className="file-input"
                                title="Choose an image file"
                            />
                            <br />
                            <button className="upload-image-button" onClick={handleUploadImage}>Upload Image</button>
                            {uploading && <p className="uploading-message">Uploading image...</p>}
                        </div>
                    </div>
                    <div className="account-section">
                        <h2><b>Account Actions</b></h2>
                        <br />
                        <button className="logOut" onClick={handleLogout}>Logout</button>
                        <button className="account-button" onClick={handleDeleteAccount}>Delete Account</button>
                        <button className="changePW" onClick={handleChangePassword}>Change Password</button>
                        <button className="update-profile-button" onClick={handleUpdateProfile}>Update Profile Information</button>
                    </div>
                </div>
            )}
            {!studentProfile && <p>Loading student profile...</p>}
            {showFullImage && profileImage && (
                <div className="full-image-overlay" onClick={() => setShowFullImage(false)}>
                    <img className="full-profile-image" src={profileImage} alt="Profile" />
                </div>
            )}

        </div>
    );
};

export default StudentProfile;
