import React, { useState, useEffect } from 'react';
import '../styles/UploadQuiz.css';
import { ToastContainer, toast } from 'react-toastify';
import BaseUrl from './BaseUrl';
import Token from './token';

const UploadQuiz = () => {
    const [questionCount, setQuestionCount] = useState(0);
    const [questionTypes, setQuestionTypes] = useState({});
    const [uploading, setUploading] = useState(false);
    
    const [quizData, setQuizData] = useState({
        title: '',
        description: '',
        timeLimit: '',
        questions: []
    });

    useEffect(() => {
      
    }, [quizData]);

    const handleAddQuestion = () => {
        setQuizData(prevData => ({
            ...prevData,
            questions: [
                ...prevData.questions,
                {
                    title: '', 
                    description: '', 
                    answer_type: 'multiple-choice',
                    score: '',
                    question_options: Array.from({ length: 4 }, () => ({ text: '', answer: false })) 
                }
            ]
        }));
        setQuestionCount(prevCount => prevCount + 1); 
    };    
    
    const handleUploadQuiz = () => {
        console.log(quizData);
        const token = Token;
        setUploading(true)
    
        if (!token) {
            console.error('No token found. Unable to upload quiz.');
            toast.error('No token found', { position: 'top-center' })
            setUploading(false)
            return;
        }
    
        const timeLimit = parseInt(quizData.timeLimit, 10);
        if (isNaN(timeLimit) || timeLimit <= 0) {
            console.error('Invalid time limit.');
            toast.error('Invalid timeLimit', { position: 'top-center' })
            setUploading(false)
            return;
        }
    
        fetch(`${BaseUrl}/api/v1/quiz/upload/1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ ...quizData, timeLimit }) 
        })
        .then(response => {
            if (response.ok) {
                console.log('Quiz uploaded successfully.');
                toast.success('Quiz uploaded successfully')
            } else {
                if(response.status == 400){
                    console.error('Failed to upload quiz:', data.message);
                    toast.error('Failed to upload quiz', { position: 'top-center' });
                    setUploading(false)
                }
            }
        })
        .catch(error => {
            console.error('Error occurred while uploading quiz:', error);
            toast.error('Failed to upload quiz', { position: 'top-center' });
            setUploading(false)
        });
        
    };
    
    const handleOptionChange = (event, questionIndex, optionIndex) => {
        const { value } = event.target;
        setQuizData(prevData => {
            const updatedQuestions = [...prevData.questions];
            const updatedOptions = updatedQuestions[questionIndex - 1].question_options.map((option, index) => ({
                text: index === optionIndex - 1 ? value : option.text,
                answer: index === optionIndex - 1
            }));
            updatedQuestions[questionIndex - 1].question_options = updatedOptions;
            return { ...prevData, questions: updatedQuestions };
        });
    };
    
    const renderQuestionOptions = (questionIndex) => {
        const questionType = questionTypes[questionIndex];
        const optionCount = (questionType === 'true-false') ? 2 : 4;
        const options = [];
        for (let i = 1; i <= optionCount; i++) {
            options.push(
                <div key={i} className='checkBox-container'>
                    <input
                        id={`option_${questionIndex}_${i}`}
                        type="text"
                        placeholder={`Option ${i}`}
                        onChange={(e) => handleOptionChange(e, questionIndex, i)}
                    />
                    <input
                        className='checkbox'
                        type="checkbox"
                        name={`answer_${questionIndex}`}
                        id={`answer_${questionIndex}_${i}`}
                        checked={quizData.questions[questionIndex - 1].question_options[i - 1].answer}
                        onChange={() => handleCheckboxChange(questionIndex, i)}
                    />
                </div>
            );
        }
        return options;
    };
    
    const handleCheckboxChange = (questionIndex, checkboxIndex) => {
        setQuizData(prevData => {
            const updatedQuestions = [...prevData.questions];
            const updatedOptions = updatedQuestions[questionIndex - 1].question_options.map((option, index) => ({
                ...option,
                answer: index === checkboxIndex - 1
            }));
            updatedQuestions[questionIndex - 1].question_options = updatedOptions.map((option, index) => ({
                ...option,
                answer: index === checkboxIndex - 1
            }));
            return { ...prevData, questions: updatedQuestions };
        });
    };
    
    const handleQuestionChange = (event, questionIndex, field) => {
        const { value } = event.target;
        setQuizData(prevData => {
            const updatedQuestions = [...prevData.questions];
            const updatedQuestion = { ...updatedQuestions[questionIndex - 1] };
            updatedQuestion[field] = value;
            updatedQuestions[questionIndex - 1] = updatedQuestion;
            return { ...prevData, questions: updatedQuestions };
        });
    };
    

    const handleQuestionTypeChange = (event, questionIndex) => {
        const { value } = event.target;
        setQuestionTypes(prevTypes => ({
            ...prevTypes,
            [questionIndex]: value
        }));
    };
    

    const renderQuestionDivs = () => {
        const questions = [];
        for (let i = 1; i <= questionCount; i++) {
            questions.push(
                <div className='questionsDiv' key={i}>
                    <h2>Question {i}</h2>
                    <input
                        type="text"
                        placeholder='title'
                        onChange={(e) => handleQuestionChange(e, i, 'title')}
                    /><br />
                    <input
                        type="text"
                        placeholder='description'
                        onChange={(e) => handleQuestionChange(e, i, 'description')}
                    /><br />
                    <select name="questionType" onChange={(event) => handleQuestionTypeChange(event, i)}>
                        <option value="multiple-choice">Multiple Choice</option>
                        <option value="fill-in-the-gap">Fill in the Gap</option>
                        <option value="true-false">True or False</option>
                    </select> <br /><br />
                    <input
                        type="number"
                        placeholder='score'
                        onChange={(e) => handleQuestionChange(e, i, 'score')}
                    /><br />
                    {renderQuestionOptions(i)}
                </div>
            );
        }
        return questions;
    };

    return (
        <div className='quizUploadContainer'>
            <h1>Upload Quiz</h1>
            <div>
                <input type="text" placeholder='Quiz Title' onChange={(e) => setQuizData({ ...quizData, title: e.target.value })} /><br />
                <input type="text" placeholder='Quiz Description' onChange={(e) => setQuizData({ ...quizData, description: e.target.value })} /><br />
                <input type="number" placeholder='Time Limit' onChange={(e) => setQuizData({ ...quizData, timeLimit: parseInt(e.target.value, 10) })} />

                {renderQuestionDivs()}

                <br />

                <button onClick={handleAddQuestion}>Add Question</button><br /><br />

                <button onClick={handleUploadQuiz}>{uploading ? "Uploading..." : "Upload Quiz"}</button>

            </div>
           <ToastContainer/>
        </div>
    );
};

export default UploadQuiz;
