
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import BaseUrl from './BaseUrl';
import Modal from 'react-modal';
import '../styles/QuizModal.css'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuizModal = ({ quizData, selectedOptionsIds, setCurrentQuestion, setSelectedOptionsIds, closeModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fillInTheBlankAnswers, setFillInTheBlankAnswers] = useState({});
    const [currentQuestion, setCurrentQuestionLocal] = useState(0);
    const [appElement, setAppElement] = useState(null);
    const [checkingAnswer, setCheckingAnswer] = useState(false);
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [incorrectQuestionsData, setIncorrectQuestionsData] = useState(null);
    console.log({ quizData, selectedOptionsIds, setCurrentQuestion, setSelectedOptionsIds, closeModal })
    useEffect(() => {
        setAppElement(document.getElementById('root'));
    }, []);
    if (!quizData || !selectedOptionsIds) {
        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                contentLabel="Error Modal"
                appElement={appElement}
            >
                <div>
                    <p>Error: Quiz data is not available.</p>
                    <button onClick={closeModal}>Close Modal</button>
                </div>
                <ToastContainer />
            </Modal>
        );
    }
    const currentQuestionData = quizData.QuizQuestions[currentQuestion];

    const handleNextQuestion = async () => {
        const isCurrentQuestionAnswered = selectedOptionsIds[currentQuestion] !== null;

        if (!isCurrentQuestionAnswered) {
            return;
        }

        const response = await fetch(`${BaseUrl}/api/v1/quiz/checkOptionsAreAnswers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                optionIds: [selectedOptionsIds[currentQuestion]]
            })
        });

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        const allAnswersCorrect = data.allAnswersCorrect;

        if (allAnswersCorrect) {
            setCurrentQuestionLocal(currentQuestion + 1);
            setCheckingAnswer(false);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestionLocal(currentQuestion - 1);
            setCheckingAnswer(false);
        }
    };

    const handleOptionSelection = (optionId) => {
        const updatedSelectedOptionsIds = [...selectedOptionsIds];

        if (currentQuestionData.answer_type === 'true-false') {
            updatedSelectedOptionsIds[currentQuestion] = optionId;
        } else {
            if (Array.isArray(updatedSelectedOptionsIds[currentQuestion])) {
                const selectedIndex = updatedSelectedOptionsIds[currentQuestion].indexOf(optionId);
                if (selectedIndex !== -1) {
                    updatedSelectedOptionsIds[currentQuestion].splice(selectedIndex, 1);
                } else {
                    updatedSelectedOptionsIds[currentQuestion].push(optionId);
                }
            } else {
                updatedSelectedOptionsIds[currentQuestion] = [optionId];
            }
        }

        setSelectedOptionsIds(updatedSelectedOptionsIds);
    };

    const submitQuiz = async () => {
        try {
            setSubmissionInProgress(true);

            const storedUser = localStorage.getItem('user');
            const parsedUser = JSON.parse(storedUser);
            const studentId = parsedUser.id;

            setIsLoading(true);

            const formattedSelectedOptions = selectedOptionsIds.map((optionId, index) => {
                const questionId = quizData.QuizQuestions[index].id;
                return { [questionId]: Array.isArray(optionId) ? optionId : [optionId] };
            });
            const token = localStorage.getItem('token');

            const response = await axios.post(
                `${BaseUrl}/api/v1/quiz/submit`,
                {
                    quizId: quizData.id,
                    studentId: studentId,
                    projectId: quizData.projectId,
                    selectedOptions: formattedSelectedOptions
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const message = response.data.message
            console.log({ message })
            if (!response.status === 200) {
                toast.error(message, { position: 'top-center', autoClose: 2000 });

                throw new Error('Failed to submit quiz');
            }

            const data = response.data;

            if (data.incorrectQuestions && data.filteredSelectedOptions) {
                setIncorrectQuestionsData(data);
            } else {
                toast.success('Quiz submitted successfully!', {
                    position: 'top-center',
                    autoClose: 2000,
                    onClose: () => {
                        closeModal();
                        window.location.reload();
                    }
                });

            }
        } catch (error) {
            console.error('Error submitting quiz:', error);
            toast.error(error.response.data.message, { position: 'top-center', autoClose: 2000 });
        } finally {
            setIsLoading(false);
            setSubmissionInProgress(false);
        }
    };



    return (
        <Modal
            isOpen={true}
            onRequestClose={submissionInProgress ? null : closeModal}
            contentLabel="Quiz Modal"
            appElement={appElement}
            id="custom-quiz-moda"
            className="notifications-modals"
            overlayClassName="notifications-overlay"
        >

            <div>
                <h2>{quizData.title}</h2>
                <p>Total Quiz Questions: {quizData.QuizQuestions.length}</p>
                <p>Current Quiz Index: {currentQuestion + 1}</p>
                {currentQuestionData && (
                    <div>
                        <p>{currentQuestionData.description}</p>
                        <p>{currentQuestionData.title}</p>
                        {currentQuestionData.answer_type === 'fill-in-the-blank' ? (
                            <div>
                                <input
                                    type="text"
                                    value={fillInTheBlankAnswers[currentQuestion] || ''}
                                    onChange={(e) => setFillInTheBlankAnswers({ ...fillInTheBlankAnswers, [currentQuestion]: e.target.value })}
                                />
                            </div>
                        ) : (
                            currentQuestionData.QuizQuestionOptions.map(option => (
                                <div key={option.id}>
                                    <div>
                                        {currentQuestionData.answer_type === 'true-false' ? (
                                            <div>
                                                <input
                                                    type="radio"
                                                    id={option.id}
                                                    value={option.id}
                                                    checked={selectedOptionsIds[currentQuestion] === option.id}
                                                    onChange={() => handleOptionSelection(option.id)}
                                                />
                                                <label htmlFor={option.id}>{option.text}</label>
                                            </div>
                                        ) : (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id={option.id}
                                                    value={option.id}
                                                    checked={Array.isArray(selectedOptionsIds[currentQuestion]) && selectedOptionsIds[currentQuestion].includes(option.id)}
                                                    onChange={() => handleOptionSelection(option.id)}
                                                    className={selectedOptionsIds[currentQuestion] && selectedOptionsIds[currentQuestion].includes(option.id) ? 'selected' : ''}
                                                />
                                                <label htmlFor={option.id}>{option.text}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
                <button className='PrevquizButton' onClick={handlePreviousQuestion} disabled={currentQuestion === 0}>
                    Previous Question
                </button>
                {submissionInProgress ? (
                    <button disabled>Submitting...</button>
                ) : (
                    currentQuestion === quizData.QuizQuestions.length - 1 ? (
                        <button className='submitButton' onClick={submitQuiz}>
                            Submit Quiz
                        </button>
                    ) : (
                        <button className='nextButton' onClick={handleNextQuestion}>
                            Next Question
                        </button>
                    )
                )}
                {incorrectQuestionsData && (
                    <div>
                        <h3>Incorrectly Answered Questions:</h3>
                        <ul>
                            {incorrectQuestionsData.incorrectQuestions.map(question => (
                                <li key={question.id}>
                                    <p>{question.title}</p>
                                    <ul>
                                        {question.QuizQuestionOptions.map(option => (
                                            <li key={option.id}>
                                                {currentQuestionData.answer_type === 'true-false' ? (
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id={option.id}
                                                            value={option.id}
                                                            checked={selectedOptionsIds[currentQuestion] === option.id}
                                                            onChange={() => handleOptionSelection(option.id)}
                                                        />
                                                        <label htmlFor={option.id}>{option.text}</label>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id={option.id}
                                                            value={option.id}
                                                            checked={Array.isArray(selectedOptionsIds[currentQuestion]) && selectedOptionsIds[currentQuestion].includes(option.id)}
                                                            onChange={() => handleOptionSelection(option.id)}
                                                            className={selectedOptionsIds[currentQuestion] && selectedOptionsIds[currentQuestion].includes(option.id) ? 'selected' : ''}
                                                        />
                                                        <label htmlFor={option.id}>{option.text}</label>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <button className='submitButton' onClick={submitQuiz}>Submit Corrected Answers</button>
                    </div>
                )}


                {!submissionInProgress && (
                    <button className='closeButton' onClick={closeModal}>Close Quiz</button>
                )}
            </div>
            <ToastContainer />
        </Modal>
    );

};

export default QuizModal;
