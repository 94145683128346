import React, { useState, useEffect } from 'react';
import YouTube from "react-youtube";
import { useNavigate } from 'react-router-dom'
import '../styles/UploadTasksAndQuizzes.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import BaseUrl from './BaseUrl';

const UploadTasksAndQuizzes = ({projectId}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [projectData, setProjectData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
          try {
              const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
              const response = await fetch(`${BaseUrl}/api/v1/project/getProject/${projectId}`, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              });
              if (!response.ok) {
                  throw new Error('Failed to fetch project data');
              }
              const data = await response.json();
              setProjectData(data);
              setLoading(false);
          } catch (error) {
              console.error(error);
              setLoading(false);
          }
      };
  
      fetchData();
  }, [projectId]);
  

    const { title, postedBy, description, concepts, resources, objectives, moreInfo } = projectData || {};

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!projectData) {
        return <p>Failed to fetch project data.</p>;
    }

    return (
        <div className="project-view-container">
            <h1 className="title">{title}</h1>
            <p className="posted-by">Posted By: {postedBy}</p>
            <p className="description">Description: {description}</p>

            <h2 className="section-title">Concepts</h2>
            <ul className="concept-list">
                {concepts.map((concept, index) => (
                    <li key={index} className="concept-item" >
                        <span className="concept-name">{concept.name}</span>
                        <YouTube videoId={getYouTubeVideoId(concept.videoLink)} opts={getYouTubeOpts()} />
                    </li>
                ))}
            </ul>

            <h2 className="section-title">Resources</h2>
            <ul className="resource-list">
                {resources.map((resource, index) => (
                    <li key={index} className="resource-item">
                        <a href={resource.link} target="_blank" rel="noopener noreferrer" className="resource-link">
                            {resource.name} <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                    </li>
                ))}
            </ul>

            <h2 className="section-title">Objectives</h2>
            <ul className="objective-list">
                {objectives.map((objective, index) => (
                    <li key={index} className="objective-item">
                        <a href={objective.link} target="_blank" rel="noopener noreferrer" className="objective-link">
                            {objective.name} <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                    </li>
                ))}
            </ul>

            <p className="more-info">More Info: {moreInfo}</p>
            <div className="navigation-buttons">
                <button id="ut" onClick={() => navigate("/upload-task")}>Upload tasks</button>
                <button id="uq" onClick={() => navigate("/upload-quiz")}>Upload Quiz</button>
            </div>
        </div>
    );
};

const getYouTubeVideoId = (videoLink) => {
    const url = new URL(videoLink);
    return url.searchParams.get("v");
};

const getYouTubeOpts = () => {
    const windowWidth = window.innerWidth;
    let height = "315";
    let width = "560";

    // Adjust dimensions for smaller screens
    if (windowWidth < 768) {
        height = "100";
        width = "200";
    }

    return {
        height,
        width,
        playerVars: {
            autoplay: 0,
        },
    };
};

export default UploadTasksAndQuizzes;
