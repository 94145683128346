import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import '../styles/StudentCoursesView.css';

const StudentCoursesView = () => {
    const [studentCourses, setStudentCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStudentCourses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${global.baseUrl}/api/v1/course/student`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.status === 200) {
                    setStudentCourses(response.data.Course);
                } else {
                    toast.error('Failed to fetch student courses', { position: 'top-center' });
                }
            } catch (error) {
                console.error('Error fetching student courses:', error);
                if (error.response) {
                    if (error.response.status === 401) {
                        toast.error('Unauthorized. Please login again.', { position: 'top-center' });
                    } else {
                        toast.error('Failed to fetch student courses', { position: 'top-center' });
                    }
                } else {
                    toast.error('Failed to connect to the server. Please try again later.', { position: 'top-center' });
                }
            }
        };
        fetchStudentCourses();
    }, []);

    const handleCourseClick = (courseId) => {
        navigate(`/course/${courseId}`);
    };

    const handleDeleteCourse = async (courseId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${global.baseUrl}/api/v1/course/student/${courseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                // Remove the deleted course from the state
                setStudentCourses(prevCourses => prevCourses.filter(course => course.id !== courseId));
                toast.success('Course deleted successfully', { position: 'top-center' });
            } else {
                toast.error('Failed to delete course', { position: 'top-center' });
            }
        } catch (error) {
            console.error('Error deleting course:', error);
            toast.error('Failed to delete course', { position: 'top-center' });
        }
    };

    return (
        <div className='Coursecontainer'>
            <header>
                <h1 className='SCVtitle'>Student Courses</h1>
            </header>
            <main>
                <div className='SCVcourse-list'>
                    {studentCourses.map(course => (
                        <div className='SCVcourse' key={course.id}>
                            <img src={course.Course.image} alt={course.Course.title} className='courseImage' onClick={() => handleCourseClick(course.Course.id)} />
                            <div className='course-details'>
                                <h2 className='course-title'>{course.Course.title}</h2>
                                <p className='course-description'>Description: {course.Course.description}</p>
                                <p className='course-fee'>Fee: {course.Course.fee_currency} {course.Course.fee}</p>

                                <button id='CSVbutton' onClick={() => handleDeleteCourse(course.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
            <ToastContainer position="top-center" />
        </div>
    );
};

export default StudentCoursesView;
