import React, { useEffect, useState } from 'react';
import '../styles/DashBoard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPalette, faP, faServer, faPaintBrush, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import image from '../assests/images/adminProfile.jpg';
import StraightProgressBar from './StraightProgressBar';
import ScheduleView from './ScheduleView';

const DashBoard = () => {
    const navigate = useNavigate();
    const { re } = useParams();

    const [courses, setCourses] = useState([]);
    const [noCoursesText, setNoCoursesText] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [studentProfile, setStudentProfile] = useState(null);

    const [ongoingCoursesActive, setOngoingCoursesActive] = useState(true);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('token');
        if (!isLoggedIn) {
            toast.error('Not logged in, returning to login page', { position: 'top-center' });
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } else {
            const storedUser = localStorage.getItem('user');
            const role = storedUser ? JSON.parse(storedUser).role : "STUDENT";

            if (re === 'te') {
                const navigateToStudentProfile = () => {
                    navigate(`/profile-${role}`);
                };
                const navigateToAdminDashboard = () => {
                    const storedUser = localStorage.getItem('user');
                    const role = JSON.parse(storedUser).role
                    if (role && role === "ADMIN") {
                        navigate(`/admin/dashboard`);
                    }
                };

                if (role === 'ADMIN') navigateToAdminDashboard();
            }

            const fetchCourses = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${global.baseUrl}/api/v1/course/student/active`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setCourses(response.data.courses);
                    } else if (response.status === 404) {
                        setNoCoursesText("No current course");
                    }
                    const responseImage = await axios.get(`${global.baseUrl}/api/v1/user/student-profile`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (responseImage.status === 200) {
                        setStudentProfile(responseImage.data.student);
                        setProfileImage(responseImage.data.student.profileImage);
                    } else {
                        console.error('Failed to fetch student profile');
                    }
                } catch (error) {
                    console.error('Error fetching courses:', error);
                    //  toast.error(error.response.data.message, { position: 'top-center' });
                }
            };
            window.scrollTo(0, 0);
            fetchCourses();
        }
    }, []);

    const navigateToCourse = (id) => {
        navigate(`/course/${id}`);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const showOngoingCourses = () => {
        setNoCoursesText('');
        setOngoingCoursesActive(true);
    };

    const showCompletedCourses = () => {
        setNoCoursesText("No completed courses");
        setOngoingCoursesActive(false);
    };

    const renderProfileImage = () => {
        if (profileImage) {
            return <img className="profile-picture" src={profileImage} alt="Profile" />;
        } else if (studentProfile) {
            const initials = studentProfile.firstName.charAt(0) + studentProfile.lastName.charAt(0);
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            const style = {
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                background: `#${randomColor}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '50px'
            };
            return <div style={style}>{initials}</div>;
        }
    };

    const storedUser = localStorage.getItem('user');
    const role = storedUser ? JSON.parse(storedUser).role : "STUDENT";
    const firstName = storedUser ? JSON.parse(storedUser).first_name : "User";
    const lastName = storedUser ? JSON.parse(storedUser).last_name : "User";

    return (
        <div className="dashBoard">
            <div className='rightSideD'>
                <div className='studentDtopDiv'>
                    {/* <div className='inputButton'>
                        <input type="text" placeholder="Search..." className="searchInput" />
                        <a href="">
                            <FontAwesomeIcon icon={faSearch} />
                        </a>
                    </div> */}
                </div>

                <div className='studentMiddleDiv'>
                    <h1 className='db_student_name'>Welcome {firstName}</h1>
                    <span id="ccag" className={ongoingCoursesActive ? "courseCategory active" : "courseCategory"} onClick={showOngoingCourses}>Ongoing Courses</span>
                    <span id="ccag" className={!ongoingCoursesActive ? "courseCategory active" : "courseCategory"} onClick={showCompletedCourses}>Completed Courses</span>
                </div>
                <br /><br />
                {noCoursesText ? (
                    <p>{noCoursesText}</p>
                ) : (
                    <div className="projects">
                        {courses.map(course => (
                            <div key={course.course.course} className="courseCard" onClick={() => navigateToCourse(course.course.course)}>
                                <img src={course.course.Course.image} alt="course image" />
                                <span className="courseTitle">{course.course.Course.title}</span>
                            </div>
                        ))}
                    </div>
                )}

                <button onClick={() => navigate('/courses/all')} className="blueButton">Explore Courses</button>
            </div>
            <div className='leftSideD'>
                <div onClick={() => navigateToProfile()} className='profile'>
                    {renderProfileImage()} <span>{firstName} {lastName}</span>
                </div>
                <br /><br /><br />
                <div className='Progress'>
                    <h1><b>Progress</b></h1><br />
                    {courses.map(course => (
                        <div key={course.course.course} className='CourseProgress'>
                            <div>
                                {course.course.Course.title === 'Backend Development' && <FontAwesomeIcon icon={faServer} />}
                                {course.course.Course.title === 'Frontend Development' && <FontAwesomeIcon icon={faPaintBrush} />}
                                <span>{course.course.Course.title}</span>
                            </div>
                            <div className='coursep'>
                                <StraightProgressBar progress={parseFloat(course.progress)} />
                            </div>
                        </div>
                    ))}
                </div>
                <br /><br /><br />
                <div className='UpcomingTasks'>
                    <ScheduleView /><br />
                </div>
            </div>
            <ToastContainer position="top-center" />
        </div>
    );
};

export default DashBoard;
