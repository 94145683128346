
import BottomDiv from './components/BottomDiv'; import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage.jsx';
import AdminDashSideBar from './components/AdminDashSideBar';
import SignUp from './components/SignUp';
import Login from './components/Login';
import SideBar from './components/SideBar';
import UploadQuiz from './components/UploadQuiz.jsx';
import UploadTask from './components/UploadTask.jsx';
import ProjectWeeks from './components/ProjectWeeks';
import UploadTasksAndQuizzes from './components/UploadTasksAndQuizzes.jsx';
import ProjectView from './components/ProjectView.jsx';
import ProjectUpload from './components/ProjectUpload';
import Quiz from './components/Quiz.jsx';
import RegisterStudentCourse from './components/RegisterStudentCourse.jsx'
import CourseView from './components/CourseView';
import CourseViewAdmin from './components/CourseViewAdmin';
import CreateProject from './components/CreateProject';
import StudentCoursesView from './components/StudentCoursesView.jsx'
import AllCoursesView from './components/AllCoursesView.jsx'
import ChangePasswordView from './components/ChangePassword.jsx'
import ResetPasswordView from './components/ResetPassword.jsx'
import StudentProfile from './components/StudentProfile.jsx'
import AdminProfile from './components/AdminProfile.jsx'
import UpdateStudent from './components/UpdateStudent.jsx'
import ScheduleView from './components/ScheduleView.jsx'
import AssignedReviews from './components/AssignedReviews.jsx'
import ReviewSubmissionPage from './components/ReviewSubmissionPage.jsx'
import CreateCourse from './components/CreateCourse.jsx'
import DashboardContent from "./components/StudentDashBoard.jsx";
import CreateTask from './components/CreateTask.jsx';
import SideBarNew from './components/SideBar.jsx';
import ProjectViewAdmin from './components/ProjectViewAdmin.jsx';
import LoginNavBar from './components/LoginNavBar.jsx';
import BlogManagement from './components/BlogManagement.jsx';
import Jobs from './components/Jobs.jsx';
import AllCoursesViewAdmin from './components/AllCoursesViewAdmin.jsx';

import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

global.baseUrl = 'https://52.204.2.95';

function App() {
  // const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem('token');
  if (!isLoggedIn) {
   // toast.error('Not logged in, returning to login page', { position: 'top-right' });
    setTimeout(() => {
      //  navigate('/login');a
    }, 3000);
  }
  return (
    <div className="App">
      <Router scrollRestoration="manual">
        <SideBarNew />
        <LoginNavBar />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/assigned-reviews' element={<AssignedReviews />} />
          <Route path='/admin/courses' element={<AllCoursesViewAdmin />} />
          <Route path='/schedule' element={<ScheduleView />} />
          <Route path='/admin/create-course' element={<CreateCourse />} />
          <Route path='/reset-password' element={<ResetPasswordView />} />
          <Route path='/update-student' element={<UpdateStudent />} />
          <Route path='/change-password/:isNewPasword' element={<ChangePasswordView />} />
          <Route path='/submit-review/:submissionId' element={<ReviewSubmissionPage />} />
          <Route path='/profile-ADMIN' element={<AdminProfile />} />
          <Route path='/profile-STUDENT' element={<StudentProfile />} />
          <Route path='/student/dashboard/:re' element={<DashboardContent />} />
          <Route path='/courses/register' element={<RegisterStudentCourse />} />
          <Route path='/courses' element={<StudentCoursesView />} />
          <Route path='/courses/all' element={<AllCoursesView />} />
          <Route path='/admin/dashboard' element={<AdminDashSideBar />} />
          <Route path='/admin/blogs/manage' element={<BlogManagement />} />
          <Route path='/course/:courseId' element={<CourseView />} />
          <Route path='/admin/course/:courseId' element={<CourseViewAdmin />} />
          <Route path='/admin/create-task/:projectId' element={<CreateTask />} />
          <Route path='/admin/create-project/:courseId' element={<CreateProject />} />
          <Route path="/upload-tasks-and-quizzes/:week" element={<UploadTasksAndQuizzes />} />
          <Route path='/upload-project/:weekId' element={<ProjectUpload />} />
          <Route path='/project-view/:projectId' element={<ProjectView />} />
          <Route path='/admin/project-view/:projectId' element={<ProjectViewAdmin />} />
          <Route path='/upload-task' element={<UploadTask />} />
          <Route path='/upload-quiz' element={<UploadQuiz />} />
          <Route path='/quiz' element={<Quiz />} />
        </Routes>
        <ToastContainer position="top-right" />
        <BottomDiv />
      </Router>
    </div>
  );
}

export default App;
