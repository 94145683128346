import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';


const RegisterStudentCourse = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://tshub.torh.tech/api/v1/course', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCourses(response.data.Courses);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
        fetchCourses();
    }, []);

    const handleChange = selectedOption => {
        setSelectedCourse(selectedOption);
    };

    const handleRegister = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://tshub.torh.tech/api/v1/course/student',
                { course: selectedCourse.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            console.log({ response })
            if (response.status === 201) {
                console.log('message', response.data.message)
                toast.success('Course Registration Succesful');
                navigate('/courses');
            } else {
                toast.error('Unexpected error occurred. Please try again.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data);
                }
            } else {
                // No response from server
                console.error('Error registering course:', error);
                toast.error('Failed to connect to the server. Please try again later.');
            }
        }
    };


    return (
        <div>
            <h2>Checkout Available Courses Below</h2>
            <Select
                value={selectedCourse}
                onChange={handleChange}
                options={courses.map(course => ({
                    value: course.id,
                    label: `${course.title} - ${course.fee_currency} ${course.fee}`
                }))}
                placeholder="Select a course or search"
                isSearchable
            />
            {selectedCourse && (
                <div>
                    <h3>Selected Course:</h3>
                    <p>{selectedCourse.label}</p>
                    <p>Description: {courses.find(course => course.id === selectedCourse.value).description}</p>
                    <button onClick={handleRegister}>Register</button>
                </div>
            )}
            <ToastContainer position="top-center" />
        </div>
    );
};

export default RegisterStudentCourse;
