import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/AdmindashSideBar.css';
import { faEdit, faHouse, faProjectDiagram, faUser, faUserGroup, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import firstImage from '../assests/svgFiles/firstImage.svg';
import AdminDashboard from './AdminDashboard';
import AddInstructor from './AddInstructor';
import ProjectManagement from './ProjectManagement';

const AdminDashSideBar = () => {
    const [activeItem, setActiveItem] = useState('dashboard');
    const navigate = useNavigate()
    const handleItemClick = (itemId) => {
        setActiveItem(itemId);
    };
    const handleAllCourses = () => {
        navigate('/admin/courses');
    };
    const navigateToBlogManagment = () => {
        navigate('/admin/blogs/manage');
    };

    return (
        <div className='adminScreenContainer'>
            <div className='adminSideBar'>
                <img src={firstImage} alt="" />
                <br /><br />
                <p id='db'
                    className={activeItem === 'dashboard' ? 'active' : ''}
                    onClick={() => handleItemClick('dashboard')}
                >
                    <FontAwesomeIcon id="icon" className='house' icon={faHouse} />
                    <span id='std'>Dashboard</span>
                </p>

                <p
                    id="com"
                    className={activeItem === 'co' ? 'active' : ''}
                    onClick={() => handleAllCourses()}
                >
                    <FontAwesomeIcon id="icon" className='project' icon={faProjectDiagram} />
                    <span id='stp'>Course Management</span>
                </p>
                <p
                    id="com"
                    className={activeItem === 'co' ? 'active' : ''}
                    onClick={() => handleItemClick('co')}
                >
                    <FontAwesomeIcon id="icon" className='project' icon={faProjectDiagram} />
                    <span id='stp'>Project Management</span>
                </p>
                <p
                    id="re"
                    className={activeItem === 're' ? 'active' : ''}
                    onClick={() => handleItemClick('re')}
                >
                    <FontAwesomeIcon id="icon" className='user' icon={faUser} />
                    <span id='stu'>User Management</span>
                </p>
                <p
                    id="ai"
                    className={activeItem === 'ai' ? 'active' : ''}
                    onClick={() => handleItemClick('ai')}
                >
                    <FontAwesomeIcon id="icon" className='userPlus' icon={faUserPlus} />
                    <span id='sta'>Add Instructor</span>
                </p>
                <p
                    id="mc"
                    className={activeItem === 'mc' ? 'active' : ''}
                    onClick={() => handleItemClick('mc')}
                >
                    <FontAwesomeIcon id="icon" icon={faUserGroup} />
                    <span id='stc'>Cohorts Management</span>
                </p>
                <p
                    id="scm"
                    className={activeItem === 'sc' ? 'active' : ''}
                    onClick={() => navigateToBlogManagment()}
                >
                    <FontAwesomeIcon id="icon" className='' icon={faEdit} />
                    <span id='stcm'>Blog Management</span>
                </p>
            </div>
            <div>
                {activeItem === 'dashboard' && <AdminDashboard />}
                {activeItem === 'ai' && <AddInstructor />}
                {activeItem === 'co' && <ProjectManagement />}
            </div>
        </div>
    );
};

export default AdminDashSideBar;