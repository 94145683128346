import BottomDiv from './BottomDiv';
import { Divide as Hamburger } from 'hamburger-react'
import React, { useState, useRef, useEffect } from "react";
import '../styles/LandingPage.css';
import login from '../assests/svgFiles/login.svg'
import firstImage from '../assests/svgFiles/firstImage.svg'
import search from '../assests/svgFiles/search.svg';
import img5 from '../assests/svgFiles/img5.svg'
import img2 from '../assests/svgFiles/img2.svg'
import img4 from '../assests/svgFiles/img4.svg';
import image1 from '../assests/svgFiles/image1.svg';
import Torus_Standing_3__L from '../assests/images/Torus_Standing_3__L 1.png';
import img6 from '../assests/images/img6.jpg'
import img7 from '../assests/images/img7.jpg'
import img8 from '../assests/images/img8.jpg'
import img9 from '../assests/images/img9.jpg'
import img10 from '../assests/images/img10.jpg'
import bArrow from '../assests/svgFiles/bArrow.svg'
import fArrow from '../assests/svgFiles/fArrow.svg'
import image3 from '../assests/svgFiles/img3.svg'
import studentimg1 from '../assests/svgFiles/studentImg.svg';
import at from '../assests/svgFiles/at.svg';
import sin from '../assests/svgFiles/sin.svg'
import fbs from '../assests/svgFiles/fbs.svg'
import ig from '../assests/svgFiles/ig.svg'
import x from '../assests/svgFiles/x.svg'
import arrow from '../assests/svgFiles/arrow.svg';
import NavBar from './NavBar';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import ExploreCurriculumModal from './ExploreCurriculumModal';
const LandingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [navItems, setnavItems] = useState(true);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const modalRef = useRef(null);

    const handleNext = () => {
        const container = document.querySelector('.posts-container');
        container.scrollBy({ left: window.innerWidth, behavior: 'smooth' });
        setCurrentIndex(prevIndex => (prevIndex === 4 ? 0 : prevIndex + 1));
    };

    const handleEnrolNow = () => {
        setTimeout(() => {
            navigate('/signup');
        }, 1000);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleExploreCurriculum = () => {
        setIsModalOpen(true);
    };

    const loggedIn = () => {
        const token = localStorage.getItem('token')
        // console.log(token)
        if (token) {

            const storedUser = localStorage.getItem('user');
            const user = JSON.parse(storedUser);

            console.log({ user })

            const role = user.role

            setTimeout(() => {
                if (role === 'ADMIN' || role === 'INSTRUCTOR') {
                    navigate('/admin/dashboard')
                } else if (role === 'STUDENT') {
                    navigate('/student/dashboard')
                }
            }, 1000);
        }
    };

    const handlePrevious = () => {
        const container = document.querySelector('.posts-container');
        container.scrollBy({ left: -window.innerWidth, behavior: 'smooth' });
        setCurrentIndex(prevIndex => (prevIndex === 0 ? 4 : prevIndex - 1));
    };

    const navigateToLogin = () => {
        setTimeout(() => {
            navigate('/login');
        }, 400);
    };

    const toggleNav = () => {
        setIsNavOpen(prev => !prev);
    };

    const toggleLoginModal = () => {
        setIsLoginModalOpen(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setIsLoginModalOpen(false);
        }
    };

    useEffect(() => {
        if (isLoginModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isLoginModalOpen]);


    return (
        <div className="mainContainer">


            {isLoginModalOpen && (
                <div ref={modalRef} className="modal">
                    <p></p>
                </div>
            )}

            <div className="body">
                <div className="textDiv1">
                    <div className="textDiv">
                        <p id="w" className="w">welcome to</p> <br></br>
                        <p id="t" className="t">Techies <strong> Skill Hub</strong></p>
                        <span id="e" className="e">Empowering Tech Enthusiasts:</span>
                        <span id="u" className="u">Unleash Your Potential Master New Skills at Techies Skill Hub.</span>
                        <span id="s" className="s">Start Your Learning Journey Today!</span>
                    </div>
                    <Link
                        to="skills"
                        smooth={true}
                        duration={500}
                    >
                        <button className="explore-button" id="explore-button">Explore courses <span className="arrow" id="arrow">&#10132;</span></button>
                    </Link>
                </div>
                <div className="imageDiv" id="imageDiv">
                    
                    <img src={image1} alt="" />
                </div>
                <img className="torus" src={Torus_Standing_3__L} alt="" />
            </div>
            <div className="skills">
                <center><p id="sct">SKILL CATEGORIES</p></center>

                <div className="softwareEngineer">

                   
                        <img src={img2} alt="" className="sfi" />
                    

                    <div id="pp">
                        <h1 id="soe">Software Engineering</h1>
                       
                            
                          
                        <div  onClick={handleExploreCurriculum} id='explore'>
                 
                            <ExploreCurriculumModal
                                isOpen={isModalOpen}
                                onRequestClose={() => setIsModalOpen(false)}
                            />
                        <p >Explore Curriculum</p> 
                        <img id="pppImage" src={search} alt="" />
                       
                      
                    </div>

                    
                        <h3 id="sotext">This course provides a comprehensive introduction to the field
                            of software engineering. Participants will gain a solid foundation in programming, version control,
                            web development, databases, software design, testing, project management, and DevOps.</h3>
                    </div>
                </div>

                <div className="productManagement">
                   
                        <img src={image3} alt="" className="sfi"/>
                  
                    <div id="pm">
                        <h1 id="pr">Product Management</h1><br />
    
             <h1 className='comingSoonBtn'>Coming Soon</h1> 

                        <span id="th">This course offers a comprehensive exploration of the principles,
                            methodologies, and responsibilities associated with product management.
                            Participants will gain insights into product strategy, development processes,
                            market analysis, and effective collaboration with cross-functional teams.</span>
                    </div>

                </div>
                <div className="productManagement">
                   
                        <img src={img4} alt="" className="sfi"/>
                  
                    <div id="pm">
                        <h1 id="pr">Content Writing</h1><br />
    
             <h1 className='comingSoonBtn'>Coming Soon</h1> 

                        <span id="th">This course delves into the advanced principles and practices of product management,
                            equipping participants with the strategic thinking,
                            leadership, and execution skills needed to drive successful product outcomes</span>
                    </div>

                </div>
                <div className="productManagement">
                   
                        <img src={img5} alt="" className="sfi"/>
                  
                    <div id="pm">
                        <h1 id="pr">UI/UX Design</h1><br />
    
             <h1 className='comingSoonBtn'>Coming Soon</h1> 

                        <span id="th">This course provides a comprehensive introduction to the fundamentals of UI/UX design,
                            focusing on the principles, methodologies, and tools essential for creating engaging
                            and user-friendly digital experiences. Participants will learn to combine aesthetics
                            with usability to design interfaces that meet user needs and business goals.</span>
                    </div>

                </div>

    
                
            </div>

            <div className="studentReviews">
                <h1>FROM OUR PAST STUDENTS</h1>
                <div className="students-profiles">
                    <div id="profile">
                        <img src={studentimg1} alt="" />
                        <div id="review">
                            <p>TSH is a really good website, I learnt so much. The tutors are so good</p>
                            <p id="t2">  you should apply today.</p>
                            <p>01/05</p>
                            <p id="name">Peace</p>
                            <div className="profileNo">
                                <button id="ppro">previous </button>
                                <img id="proi" src={bArrow} alt="" />
                                <img id="proi2" src={fArrow} alt="" />
                                <button >next</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="enrnow" className="enrnow" onClick={handleEnrolNow}>
                    <span>Enrol Now</span>
                    <img src={arrow} alt="" />
                </div>
            </div>



        </div >
    )
}

export default LandingPage;