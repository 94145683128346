import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import axios from 'axios';
import "../styles/CreateCourse.css"; // Import the CSS file

const CreateCourse = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        limit: 100000,
        moreInfo: '',
        fee: 0,
        fee_currency: 'usd',
        level: 'Beginner',
        endDate: '',
        startDate: '',
        image: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        if (!token) {
            // Handle case where token is missing
            return;
        }

        const courseData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'image') {
                courseData.append(key, value);
            } else {
                courseData.append(key, value);
            }
        });

        try {
            const response = await axios.post(`${global.baseUrl}/api/v1/course`, courseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log({ response })
            if (response.status === 201) {
                toast.success('Course creation Successful');
            } else {
                toast.error(response.data.message);
            }

        } catch (error) {

            console.error('Error creating course:', error);
            toast.error(error.response.data.error);

        }
    };

    return (
        <div className="CreateCourseMainCont">
            <h2 className="CreateCourseTitle">Create Course</h2>
            <form onSubmit={handleSubmit} className="CreateCourseForm">
                {/* Form fields */}
                {/* Title */}
                <label htmlFor="title" className="InputTitle">Title</label>
                <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="CreateCourseInput" />

                {/* Description */}
                <label htmlFor="description" className="InputTitle">Description</label>
                <textarea id="description" name="description" value={formData.description} onChange={handleChange} className="CreateCourseTextarea"></textarea>

                {/* Limit */}
                <label htmlFor="limit" className="InputTitle">Limit</label>
                <input type="number" id="limit" name="limit" value={formData.limit} onChange={handleChange} className="CreateCourseInput" />

                {/* More Info */}
                <label htmlFor="moreInfo" className="InputTitle">More Info</label>
                <textarea id="moreInfo" name="moreInfo" value={formData.moreInfo} onChange={handleChange} className="CreateCourseTextarea"></textarea>

                {/* Fee */}
                <label htmlFor="fee" className="InputTitle">Fee</label>
                <input type="number" id="fee" name="fee" value={formData.fee} onChange={handleChange} className="CreateCourseInput" />

                {/* Fee Currency */}
                <label htmlFor="fee_currency" className="InputTitle">Fee Currency</label>
                <select id="fee_currency" name="fee_currency" value={formData.fee_currency} onChange={handleChange} className="CreateCourseSelect">
                    <option value="usd">USD</option>
                    <option value="naira">Naira</option>

                </select>

                {/* Level */}
                <label htmlFor="level" className="InputTitle">Level</label>
                <select id="level" name="level" value={formData.level} onChange={handleChange} className="CreateCourseSelect">
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                </select>

                {/* Start Date */}
                <label htmlFor="startDate" className="InputTitle">Start Date</label>
                <input type="date" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} className="CreateCourseInput" />

                {/* End Date */}
                <label htmlFor="endDate" className="InputTitle">End Date</label>
                <input type="date" id="endDate" name="endDate" value={formData.endDate} onChange={handleChange} className="CreateCourseInput" />

                {/* Image */}
                <label htmlFor="image" className="InputTitle">Image</label>
                <input type="file" id="image" name="image" onChange={handleImageChange} accept="image/*" className="CreateCourseInput" />

                {/* Submit button */}
                <button type="submit" className="CreateCourseButton">Create Course</button>
            </form>

            {/* Toast container */}
            <ToastContainer position="top-center" />
        </div>
    );
};

export default CreateCourse;
