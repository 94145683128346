import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import '../styles/SideBar.css'
import { faGraduationCap, faUsers, faBook, faHouse, faBookOpen, faAward, faCalendarAlt, faComment, faHeadset, faUser, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firstImage from '../assests/svgFiles/firstImage.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import LoginNavBar from './LoginNavBar';
import BackButton from './BackButton';
const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [certificates, setCertificates] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showAllNotifications, setShowAllNotifications] = useState(false);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');

                let notificationsUrl = 'https://tshub.torh.tech/api/v1/notification/unread';
                if (showAllNotifications) {
                    notificationsUrl = 'https://tshub.torh.tech/api/v1/notification';
                }

                const notificationsResponse = await axios.get(notificationsUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (notificationsResponse.data.message === "success") {
                    setNotifications(notificationsResponse.data.notifications);
                    if (showAllNotifications) {
                        setUnreadNotificationsCount(notificationsResponse.data.notifications.filter(notification => !notification.is_read).length);
                    } else {
                        setUnreadNotificationsCount(notificationsResponse.data.notifications.length);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [showAllNotifications]);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
        setModalIsOpen(!modalIsOpen);
    };

    const toggleAllCourses = () => {
        navigate('/courses/all');
    };

    const markNotificationAsRead = async (notificationId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${global.baseUrl}/api/v1/notification/read/${notificationId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const notificationsResponse = await axios.get('https://tshub.torh.tech/api/v1/notification/unread', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (notificationsResponse.data.message === "success") {
                setNotifications(notificationsResponse.data.notifications);
                setUnreadNotificationsCount(notificationsResponse.data.notifications.length);
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const navigateToStudentCourse = () => {
        navigate('/courses');
    };

    const navigateToProfile = () => {
        const storedUser = localStorage.getItem('user');
        const role = storedUser ? JSON.parse(storedUser).role : "STUDENT";
        navigate(`/profile-${role}`);
    };

    const navigateToAssignedReviews = () => {
        navigate(`/assigned-reviews`);
    };

    const navigateToSchedule = () => {
        navigate('/schedule');
    };

    const isLoggedIn = localStorage.getItem('token');

    // Check if the current location is the landing page
    const isLandingPage = location.pathname === '/';
    const isSignUpPage = location.pathname === '/signup';
    const isLoginPage = location.pathname === '/login';
    const isAdmin = location.pathname.startsWith('/admin/'); // Check if the current path starts with '/course/'


    if (!isLoggedIn || isLoginPage || isLandingPage || isSignUpPage || isAdmin) {
        return null;
    }
    const handleHome = () => {

        navigate('/');
    };

    return (
        <div className="taskScreenContainer">
            <div className="sideBar">

                <img onClick={handleHome} src={firstImage} alt="" />
                <br /><br />
                <BackButton />

                <p onClick={() => navigate('/student/dashboard/fe')}>
                    <FontAwesomeIcon icon={faHouse} />
                    <span>Dashboard</span>
                </p>
                <p onClick={navigateToStudentCourse}>
                    <FontAwesomeIcon icon={faGraduationCap} />
                    <span>My Courses</span>
                </p>
                <p onClick={toggleAllCourses}>
                    <FontAwesomeIcon icon={faBook} />
                    <span>All Courses</span>
                </p>
                <p onClick={() => navigateToAssignedReviews()}>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Assigned Reviews</span>
                </p>
                <p onClick={toggleNotifications}>
                    <FontAwesomeIcon icon={faBell} />
                    <span>Notifications  </span>
                    {unreadNotificationsCount > 0 && !showAllNotifications && (
                        <span style={{ color: 'red' }} className="notification-count">
                            {unreadNotificationsCount}
                        </span>
                    )}
                </p>
                <p onClick={() => navigate('/resources')}>
                    <FontAwesomeIcon icon={faBookOpen} />
                    <span>Resources</span>
                </p>
                <p onClick={() => navigate('/my-certificate')}>
                    <FontAwesomeIcon icon={faAward} />
                    <span>My certificate</span>
                </p>
                <p onClick={navigateToSchedule}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <span>Schedule</span>
                </p>
                <p onClick={() => navigate('/feedback')}>
                    <FontAwesomeIcon icon={faComment} />
                    <span>Feedback</span>
                </p>
                <p onClick={() => navigate('/jobs')}>
                    <FontAwesomeIcon icon={faHeadset} />
                    <span>Jobs</span>
                </p>
                <p onClick={() => navigate('/support')}>
                    <FontAwesomeIcon icon={faHeadset} />
                    <span>Support</span>
                </p>
                <p onClick={() => navigateToProfile()}>
                    <FontAwesomeIcon icon={faUser} />
                    <span>Profile</span>
                </p>

            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleNotifications}
                contentLabel="Notifications Modal"
                className="notifications-modal"
                overlayClassName="notifications-overlay"
                ariaHideApp={false}
            >
                <div className="notifications-container">
                    <h3> <b>Notifications</b></h3>
                    <br />
                    {notifications.length > 0 ? (
                        notifications.map(notification => (
                            <div key={notification.id} className="notification-item">
                                <h4 className="notif-tit">{notification.title}</h4>
                                <p>{notification.message}</p>
                                <button id="toggle-mark" onClick={() => markNotificationAsRead(notification.id)}>Mark as Read</button>
                            </div>
                        ))
                    ) : (
                        <p>No notifications</p>
                    )}
                    <button id="toggle-button" onClick={() => setShowAllNotifications(!showAllNotifications)}>
                        {showAllNotifications ? 'View All Unread Notifications' : 'View All Notifications'}
                    </button>
                    <button className="close-button" onClick={toggleNotifications}>x</button>
                </div>
            </Modal>

        </div>
    );
}

export default SideBar;
