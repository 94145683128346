import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import login from '../assests/svgFiles/login.svg';
import firstImage from '../assests/svgFiles/firstImage.svg';
import '../styles/LoginNavBar.css';
import NavBackButton from './NavBackButton';

const NavBar = ({ navItems }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const modalRef = useRef(null);

    const toggleNav = () => {
        setIsNavOpen(prev => !prev);
    };

    const handleNavigationClick = () => {
        setIsNavOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.sidebar')) {
                setIsNavOpen(false);
            }
        };

        if (isNavOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isNavOpen]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        navigate('/login');
    };
    const handleHome = () => {

        navigate('/');
    };

    const isLoggedIn = localStorage.getItem('token');
    const isLandingPage = location.pathname === '/';
    const shouldShowHorizontalNav = window.innerWidth > 768 && isLandingPage;
    const shouldShowHamburger = window.innerWidth > 768
    return (
        <>

            {shouldShowHorizontalNav && (
                <div id="landingNav" className={`topNav ${isLandingPage ? 'with-box-shadow' : ''}`}>
                    <nav>
                        <img onClick={handleHome} className="img" src={firstImage} alt="" />
                        {/* Render navigation links */}
                        {isLoggedIn ? (
                            <>
                                <NavLink to="/">Home</NavLink>
                                <NavLink to="/student/dashboard/fe">Dashboard</NavLink>
                                <NavLink to="/courses">My Courses</NavLink>
                                <NavLink to="/courses/all">All Courses</NavLink>
                                <NavLink to="/assigned-reviews">Assigned Reviews</NavLink>
                                <NavLink to="/notifications">Notifications</NavLink>
                                <NavLink to="/resources">Resources</NavLink>
                                <NavLink to="/my-certificate">My Certificate</NavLink>
                                <NavLink to="/schedule">Schedule</NavLink>
                                <NavLink to="/feedback">Feedback</NavLink>
                                <NavLink to="/profile-STUDENT">Profile</NavLink>
                                <a href="#" className="login-button" onClick={handleLogout}>
                                    <span>Logout</span>
                                    <img className="login-icon" src={login} alt="Login" />
                                </a>
                            </>
                        ) : (
                            <>
                                <NavLink to="/">Home</NavLink>
                                <NavLink to="/about">About Us</NavLink>
                                <NavLink to="/contact">Contact Us</NavLink>
                                <NavLink to="/login">Login</NavLink>
                                <NavLink to="/signup">Sign Up</NavLink>
                            </>
                        )}
                    </nav>
                </div>
            )}
            {/* Render the hamburger menu for mobile */}
            <div id="navMobile" style={{ zIndex: '1', }} className="topNav">

                
                    <img onClick={handleHome} className="Navimg" src={firstImage} alt="logo" />
                    <button className="nav-toggle-button" onClick={toggleNav}>
                        <Hamburger id="hamburger" size={40} toggled={isNavOpen} />
                    </button>
   
            </div>
            <NavBackButton id="backButton" />

            {/* Render the sidebar */}
            {!isLoggedIn && !shouldShowHorizontalNav && (
                <div id="mySidebar" className="sidebar" style={{ width: isNavOpen ? '250px' : '0', zIndex: '2' }} ref={modalRef}>
                    <NavLink to="/" onClick={handleNavigationClick}>Home</NavLink>
                    <NavLink to="/about" onClick={handleNavigationClick}>About Us</NavLink>
                    <NavLink to="/contact" onClick={handleNavigationClick}>Contact Us</NavLink>
                    <NavLink to="/login" onClick={handleNavigationClick}>Login</NavLink>
                    <NavLink to="/signup" onClick={handleNavigationClick}>Sign Up</NavLink>
                </div>
            )}
            {/* Render the sidebar for logged-in users */}
            {isLoggedIn && !shouldShowHorizontalNav && (
                <div id="mySidebar" className="sidebar" style={{ width: isNavOpen ? '250px' : '0', zIndex: '5' }} ref={modalRef}>
                    <NavLink to="/" onClick={handleNavigationClick}>Home</NavLink>
                    <NavLink to="/student/dashboard/fe" onClick={handleNavigationClick}>Dashboard</NavLink>
                    <NavLink to="/courses" onClick={handleNavigationClick}>My Courses</NavLink>
                    <NavLink to="/courses/all" onClick={handleNavigationClick}>All Courses</NavLink>
                    <NavLink to="/assigned-reviews" onClick={handleNavigationClick}>Assigned Reviews</NavLink>
                    <NavLink to="/notifications" onClick={handleNavigationClick}>Notifications</NavLink>
                    <NavLink to="/resources" onClick={handleNavigationClick}>Resources</NavLink>
                    <NavLink to="/my-certificate" onClick={handleNavigationClick}>My Certificate</NavLink>
                    <NavLink to="/schedule" onClick={handleNavigationClick}>Schedule</NavLink>
                    <NavLink to="/feedback" onClick={handleNavigationClick}>Feedback</NavLink>
                    <NavLink to="/profile-STUDENT" onClick={handleNavigationClick}>Profile</NavLink>
                    <a href="#" id='navLoginButton' className="navLoginButton" onClick={handleLogout}>
                        <span>Logout</span>
                        <img className="login-icon" src={login} alt="Login" />
                    </a>
                </div>
            )}
        </>
    );
};

export default NavBar;