import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ResetPasswordView = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleResetPassword = async () => {
        try {
            const response = await axios.post('https://tshub.torh.tech/api/v1/user/reset-password', {
                email: email
            });

            if (response.status === 200) {
                toast.success(
                    <div>
                        <p>{response.data.message}</p>
                        <button onClick={() => navigate('/login')}>Okay</button>
                    </div>,
                    { position: 'top-center', autoClose: 5000 }
                );
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } else {
                toast.error(response.data.message, { position: 'top-center' });
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, { position: 'top-center' });
            } else {
                toast.error('Failed to reset password', { position: 'top-center' });
            }
        }
    };

    return (
        <div>
            <h2>Reset Password</h2>
            <div>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <button onClick={handleResetPassword}>Reset Password</button>
            <ToastContainer position="top-center" />
        </div>
    );
};

export default ResetPasswordView;
