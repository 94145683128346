import React, { useState, useEffect } from 'react';

const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchDescription, setSearchDescription] = useState('');
    const [searchLocation, setSearchLocation] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let url = 'https://jobs.github.com/';
            if (searchDescription || searchLocation) {
                url += `?description=${encodeURIComponent(searchDescription)}&location=${encodeURIComponent(searchLocation)}`;
            }
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch job listings');
            }
            const data = await response.json();
            setJobs(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching job listings:', error);
            setLoading(false);
        }
    };

    const handleSearch = () => {
        fetchData();
    };

    return (
        <div>
            <h2>GitHub Job Search</h2>
            <div>
                <input
                    type="text"
                    placeholder="Enter job description"
                    value={searchDescription}
                    onChange={(e) => setSearchDescription(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Enter location"
                    value={searchLocation}
                    onChange={(e) => setSearchLocation(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ul>
                    {jobs.map((job) => (
                        <li key={job.id}>
                            <h3>{job.title}</h3>
                            <p>{job.company}</p>
                            <p>{job.location}</p>
                            <p>{job.created_at}</p>
                            {/* Add more job details as needed */}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Jobs;
