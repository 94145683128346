import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/ProjectManagement.css";

const ProjectManagement = () => {
  const navigate = useNavigate(); 

  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setCourseDescription(e.target.value);
  };

  const handleDurationChange = (e) => {
    setCourseDuration(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCreateCourse = () => {
    console.log("Course created:", {
      name: courseName,
      description: courseDescription,
      duration: courseDuration,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const dummyCourse = {
    name: "SoftWare Engineering",
    description: "This is a dummy course",
    duration: 1,
    startDate: "2024-03-10",
    endDate: "2025-03-10",
  };


  const handleCourseClick = () => {
   navigate('/course/'+dummyCourse.name)
  };

  return (
    <div className="project-management">
      <div className="course-creation">
        <h1>Upload course</h1>
        <label htmlFor="name" id="cname">Course Name:</label>
        <input type="text" id="courseName" value={courseName} onChange={handleNameChange} />
        <label htmlFor="description" id="cdesc">Course Description:</label>
        <textarea id="description" value={courseDescription} onChange={handleDescriptionChange} />
        <label htmlFor="duration" id="cdiy">Course Duration (in years):</label>
        <input type="number" id="duration" value={courseDuration} onChange={handleDurationChange} />
        <label htmlFor="start-date" id="sdate">Start Date:</label>
        <input type="date" id="start-date" value={startDate} onChange={handleStartDateChange} />
        <label htmlFor="end-date" id="edate">End Date:</label>
        <input type="date" id="end-date" value={endDate} onChange={handleEndDateChange} />
        <button onClick={handleCreateCourse} id="ccourse">Create Course</button>
      </div>
      <div className="course-list">
        <h1 id="courselist">Course List</h1>
        <div className="fcourse" onClick={handleCourseClick}>
          <h1 id="dcn">{dummyCourse.name}</h1>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagement;
