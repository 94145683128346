import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/UpdateStudent.css';

const BaseUrl = 'https://tshub.torh.tech';

const UpdateStudent = () => {
    const [student, setStudent] = useState({
        firstName: '',
        lastName: '',
        gitUsername: '',
        phoneNumber: '',
        selectedCountry: null,
        selectedSex: null,
    });

    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [sex, setSex] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countriesResponse, sexResponse] = await Promise.all([
                    axios.get(`${BaseUrl}/api/v1/user/countries`),
                    axios.get(`${BaseUrl}/api/v1/user/sex`)
                ]);
                setCountries(countriesResponse.data.countries.map(country => ({
                    value: country.id,
                    label: country.country
                })));
                setSex(sexResponse.data.roles.map(role => ({
                    value: role.id,
                    label: role.sex
                })));
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BaseUrl}/api/v1/user/student-profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const studentData = response.data.student;
                    setStudent({
                        firstName: studentData.firstName,
                        lastName: studentData.lastName,
                        gitUsername: studentData.gitUsername,
                        phoneNumber: studentData.phoneNumber,
                        selectedCountry: countries.find(country => country.label === studentData.User.country),
                        selectedSex: sex.find(sex => sex.label === studentData.User.sex),
                    });
                } else {
                    console.error('Failed to fetch student profile');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const isValidGitHubUsername = async (username) => {
        try {
            const response = await axios.get(`https://api.github.com/users/${username}`);
            return response.status === 200;
        } catch (error) {
            return false;
        }
    };

    const handleUpdate = async () => {
        setLoading(true);
        const { firstName, lastName, gitUsername, phoneNumber, selectedCountry, selectedSex } = student;
        if (gitUsername && !await isValidGitHubUsername(gitUsername)) {
            toast.error('Invalid GitHub username', { position: 'top-center' });
            setLoading(false);
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${BaseUrl}/api/v1/user/update-student`, {
                firstName,
                lastName,
                gitUsername,
                phoneNumber,
                country: selectedCountry ? selectedCountry.value : null,
                sex: selectedSex ? selectedSex.value : null
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                toast.success('Student information updated successfully', { position: 'top-center' });
            } else {
                toast.error('Failed to update student information', { position: 'top-center' });
            }
        } catch (error) {
            console.error('Error updating student:', error);
            toast.error('Failed to update student information', { position: 'top-center' });
        } finally {
            setLoading(false);
        }
    };

    const isFormNotEmpty = () => {
        const { firstName, lastName, gitUsername, phoneNumber, selectedCountry, selectedSex } = student;
        return (
            firstName.trim() ||
            lastName.trim() ||
            gitUsername.trim() ||
            phoneNumber.trim() ||
            selectedCountry ||
            selectedSex
        );
    };

    const handleChange = (field, value) => {
        setStudent({ ...student, [field]: value });
    };

    return (
        <div className='UpdateStudentMainCont'>
            <h2>Update Student Information</h2>
            <input
                type="text"
                value={student.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                placeholder="First Name"
            />
            <input
                type="text"
                value={student.lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
                placeholder="Last Name"
            />
            <input
                type="text"
                value={student.gitUsername}
                onChange={(e) => handleChange('gitUsername', e.target.value)}
                placeholder="GitHub Username"
            />
            <input
                type="text"
                value={student.phoneNumber}
                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                placeholder="Phone Number"
            />
            <br />
            <div className='selectInput'>
                <Select
                    value={student.selectedCountry}
                    onChange={(value) => handleChange('selectedCountry', value)}
                    options={countries}
                    placeholder="Select Country"
                    isSearchable
                />
                <Select
                    value={student.selectedSex}
                    onChange={(value) => handleChange('selectedSex', value)}
                    options={sex}
                    placeholder="Select Sex"
                    isSearchable
                />
            </div>
            <br />
            <button onClick={handleUpdate} disabled={loading || !isFormNotEmpty()}>Update</button>
            <ToastContainer position="top-center" />
        </div>
    );
};

export default UpdateStudent;
