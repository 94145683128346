import React from 'react';
import PropTypes from 'prop-types';
import '../styles/StraightProgressBar.css';

const StraightProgressBar = ({ progress }) => {
    return (
        <div className="straight-progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
    );
};

StraightProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
};

export default StraightProgressBar;
