import { toast, ToastContainer } from 'react-toastify';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './CreateTask.css'; // Import CSS file for styling

const CreateTask = () => {
    const { projectId } = useParams();

    const [taskData, setTaskData] = useState({
        name: '',
        type: '',
        score: '',
        link_solution: '',
        instructions: '',
        submissionInstructions: '',
        script: '',
        minimumScore: '',
        requiredSubmissionType: '', // Default to file submission
        gradingRubric: {
            title: '',
            description: '',
            total_score: '',
            criteria: [], 
            grading_instructions: ''
        },
        file: null // File field
    });

    // Function to handle changes in the form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaskData({ ...taskData, [name]: value });
    };

    // Function to handle changes in the grading rubric fields
    const handleGradingRubricChange = (value, fieldName) => {
        setTaskData({
            ...taskData,
            gradingRubric: {
                ...taskData.gradingRubric,
                [fieldName]: value
            }
        });
    };

    // Function to handle changes in the criteria of the grading rubric
    const handleCriterionChange = (index, fieldName, value) => {
        const updatedCriteria = [...taskData.gradingRubric.criteria];
        updatedCriteria[index][fieldName] = value;
        setTaskData({
            ...taskData,
            gradingRubric: {
                ...taskData.gradingRubric,
                criteria: updatedCriteria
            }
        });
    };

    // Function to add a new criterion to the grading rubric
    const handleAddCriterion = () => {
        setTaskData({
            ...taskData,
            gradingRubric: {
                ...taskData.gradingRubric,
                criteria: [...taskData.gradingRubric.criteria, { name: '', description: '', weightage: '' }]
            }
        });
    };

    // Function to remove a criterion from the grading rubric
    const handleRemoveCriterion = (index) => {
        const updatedCriteria = [...taskData.gradingRubric.criteria];
        updatedCriteria.splice(index, 1);
        setTaskData({
            ...taskData,
            gradingRubric: {
                ...taskData.gradingRubric,
                criteria: updatedCriteria
            }
        });
    };

    // Function to handle file input change
    const handleFileChange = (e) => {
        setTaskData({ ...taskData, file: e.target.files[0] });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('name', taskData.name);
            formData.append('type', taskData.type);
            formData.append('score', taskData.score);
            formData.append('link_solution', taskData.link_solution);
            formData.append('instructions', taskData.instructions);
            formData.append('submission_instructions', taskData.submissionInstructions);
            formData.append('script', taskData.script);
            formData.append('minimum_score', taskData.minimumScore);
            formData.append('requiredSubmissionType', taskData.requiredSubmissionType);
            formData.append('projectId', projectId);
            formData.append('grading_rubric', JSON.stringify(taskData.gradingRubric));
            formData.append('file', taskData.file);
            const token = localStorage.getItem('token');
            
            const response = await axios.post(`${global.baseUrl}/api/v1/task/uploadTask`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            console.log('Task uploaded successfully:', response.data);
            if (response.status === 201) {
                toast.success('Task uploaded successfully', { position: 'top-center' });
               
               
            } else {
                console.error('Failed to upload');
                toast.error('Failed upload', { position: 'top-center' });

            }
        } catch (error) {
            console.error('Error uploading task:', error);
            toast.error('Failed to upload', { position: 'top-center' });

           
        }
    };

    // Quill modules and formats
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'indent',
        'link', 'image', 'code-block',
        'color', 'background'
    ];

    return (
        <div className="CreateTaskContainer">
            <h2>Create Task</h2>
            <form onSubmit={handleSubmit}>
                {/* Input fields for task details */}
                <input type="text" name="name" value={taskData.name} onChange={handleChange} placeholder="Task Name" />
                <input type="text" name="type" value={taskData.type} onChange={handleChange} placeholder="Task Type" />
                <input type="number" name="score" value={taskData.score} onChange={handleChange} placeholder="Score" />
                <input type="text" name="link_solution" value={taskData.link_solution} onChange={handleChange} placeholder="Link Solution" />
                {/* Input field for total score */}
                <input type="number" name="gradingRubric.total_score" value={taskData.gradingRubric.total_score} onChange={handleChange} placeholder="Total Score" />

                {/* Dropdown for required submission type */}
                <select name="requiredSubmissionType" value={taskData.requiredSubmissionType} onChange={handleChange}>
                    <option value="github">GitHub</option>
                    <option value="file">File</option>
                    <option value="link">Link</option>
                </select>

                {/* React Quill for instructions */}
                <ReactQuill
                    value={taskData.instructions}
                    onChange={(value) => setTaskData({ ...taskData, instructions: value })}
                    placeholder="Instructions"
                    modules={modules}
                    formats={formats}
                />

                {/* React Quill for submission instructions */}
                <ReactQuill
                    value={taskData.submissionInstructions}
                    onChange={(value) => setTaskData({ ...taskData, submissionInstructions: value })}
                    placeholder="Submission Instructions"
                    modules={modules}
                    formats={formats}
                />

                {/* React Quill for script */}
                <ReactQuill
                    value={taskData.script}
                    onChange={(value) => setTaskData({ ...taskData, script: value })}
                    placeholder="Script"
                    modules={modules}
                    formats={formats}
                />

                <input type="number" name="minimumScore" value={taskData.minimumScore} onChange={handleChange} placeholder="Minimum Score" />

                {/* Input fields for grading rubric */}
                <ReactQuill
                    value={taskData.gradingRubric.title}
                    onChange={(value) => handleGradingRubricChange(value, 'title')}
                    placeholder="Rubric Title"
                    modules={modules}
                    formats={formats}
                />
                <ReactQuill
                    value={taskData.gradingRubric.description}
                    onChange={(value) => handleGradingRubricChange(value, 'description')}
                    placeholder="Rubric Description"
                    modules={modules}
                    formats={formats}
                />
                <input type="number" name="gradingRubric.total_score" value={taskData.gradingRubric.total_score} onChange={handleChange} placeholder="Total Score" />

                {/* Input fields for each criterion */}
                {taskData.gradingRubric.criteria.map((criterion, index) => (
                    <div key={index}>
                        <input type="text" name={`gradingRubric.criteria[${index}].name`} value={criterion.name} onChange={(e) => handleCriterionChange(index, 'name', e.target.value)} placeholder={`Criterion Name ${index + 1}`} />
                        <ReactQuill
                            value={criterion.description}
                            onChange={(value) => handleCriterionChange(index, 'description', value)}
                            placeholder="Criterion Description"
                            modules={modules}
                            formats={formats}
                        />
                        <input type="number" name={`gradingRubric.criteria[${index}].weightage`} value={criterion.weightage} onChange={(e) => handleCriterionChange(index, 'weightage', e.target.value)} placeholder="Criterion Weightage" />
                        {index > 0 && <button type="button" onClick={() => handleRemoveCriterion(index)}>Remove Criterion</button>}
                    </div>
                ))}
                <button type="button" onClick={handleAddCriterion}>Add Criterion</button>

                {/* File input for code submission */}
                <input type="file" name="file" onChange={handleFileChange} />
                <button type="submit">Upload Task</button>
            </form>
            <ToastContainer position="top-right" />

        </div>
    );
};

export default CreateTask;
