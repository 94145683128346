import LoginNavBar from './LoginNavBar';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import BaseUrl from './BaseUrl';
import '../styles/AssignedReviews.css'
import SideBar from './SideBar';

const AssignedReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchAssignedReviews = async () => {
            try {
                const token = localStorage.getItem('token');

                const response = await axios.get(`${BaseUrl}/api/v1/task/assigned-reviews`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data.reviews) {
                    setReviews(response.data.reviews);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching assigned reviews:', error);
                setLoading(false);
            }
        };

        fetchAssignedReviews();
    }, []);

    return (
        <div className='AssignedReviewsMainContainer'>

            <div className='contentContainer'>
                <h2>Assigned Reviews</h2>
                {loading ? (
                    <p>Loading...</p>
                ) : reviews.length === 0 ? (
                    <p>No assigned reviews found.</p>
                ) : (
                    <ul>
                        {reviews.map((review) => (
                            <li key={review.id}>
                                {/* Add the 'review-card' class to style each review as a card */}
                                <div className="review-card" onClick={() => navigate(`/submit-review/${review.submission}`)} style={{ cursor: 'pointer' }}>
                                    <h3>Review ID: {review.id}</h3>
                                    <p>Student: {review.student.first_name} {review.student.last_name}</p>
                                    <p>Reviewer: {review.reviewer.first_name} {review.reviewer.last_name}</p>
                                    <p>Project Title: {review.Project.title}</p>
                                    <p>Task: {review.Task.name}</p>
                                    <p>Date Assigned: {review.date_assigned}</p>
                                    <p>Reviewed: {review.reviewed ? 'Yes' : 'No'}</p>
                                </div>
                            </li>
                        ))}

                    </ul>
                )}
            </div>
        </div>
    );
};

export default AssignedReviews;
