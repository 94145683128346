import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/quiz.css';
import BaseUrl from './BaseUrl';

const Quiz = () => {
    const [quizData, setQuizData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOptionsIds, setSelectedOptionsIds] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(BaseUrl + "/api/v1/quiz/4");
                if (!response.ok) {
                    throw new Error('Failed to fetch quiz data');
                }
                const data = await response.json();
                setQuizData(data);
                setSelectedOptionsIds(Array(data.quiz.QuizQuestions.length).fill([]).map(() => []));
            } catch (error) {
                console.error('Error fetching quiz data:', error);
            }
        };
        fetchData();
    }, []);

    const handleNextQuestion = () => {
        if (currentQuestion < quizData.quiz.QuizQuestions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            console.log("End of quiz");
            submitQuiz();
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleOptionSelection = async (optionId) => {
        try {
            const response = await fetch(`${BaseUrl}/api/v1/quiz/check/${optionId}`);
            if (!response.ok) {
                throw new Error('Failed to check answer');
            }
            const data = await response.json();
            if (!data.isAnswer) {
                toast.error('Incorrect answer! Please try again.', { position: 'top-center' });
            } else {
                const updatedSelectedOptionsIds = [...selectedOptionsIds];
                updatedSelectedOptionsIds[currentQuestion] = [...updatedSelectedOptionsIds[currentQuestion], optionId];
                setSelectedOptionsIds(updatedSelectedOptionsIds);
            }
        } catch (error) {
            console.error('Error checking answer:', error);
        }
    };

    const submitQuiz = async () => {
        try {
            const selectedOptions = quizData.quiz.QuizQuestions.map((question, index) => ({
                [question.id]: selectedOptionsIds[index]
            }));
            const requestBody = {
                quizId: quizData.quiz.id,
                studentId: parseInt(localStorage.getItem('userId')),
                projectId: quizData.quiz.projectId,
                selectedOptions: selectedOptions
            };
            console.log(requestBody);

            const response = await fetch(`${BaseUrl}/api/v1/quiz/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            if (!response.ok) {
                throw new Error('Failed to submit quiz');
            }
            else {
                console.log('Quiz submitted successfully');
                toast.success('Quiz submitted successfully', { position: 'top-center' });
                setTimeout(() => {
                    navigate('projectView/'+quizData.quiz.projectId);
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting quiz:', error);
        }
    };

    if (!quizData) {
        return <div>Loading...</div>;
    }

    const areOptionsSelected = selectedOptionsIds[currentQuestion].length > 0;

    return (
        <div className="quiz-container">
            <h1 className="quiz-title">{quizData.quiz.title}</h1>
            <div className="question">
                <h2>{quizData.quiz.QuizQuestions[currentQuestion].title}</h2>
                {quizData.quiz.QuizQuestions[currentQuestion].QuizQuestionOptions && (
                    <ul className="options-list">
                        {quizData.quiz.QuizQuestions[currentQuestion].QuizQuestionOptions.map((option, index) => (
                            <li key={index} className="option-item">
                                <label>
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestion}`}
                                        value={option.text}
                                        checked={selectedOptionsIds[currentQuestion].includes(option.id)}
                                        onChange={() => handleOptionSelection(option.id)}
                                    />
                                    {option.text}
                                </label>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="button-container">
                {currentQuestion > 0 && (
                    <button className="back-button" onClick={handlePreviousQuestion}>Go Back</button>
                )}
                <button className="next-button" onClick={handleNextQuestion} disabled={!areOptionsSelected}>
                    {currentQuestion === quizData.quiz.QuizQuestions.length - 1 ? "End Quiz" : "Next"}
                </button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Quiz;
